import { initializeApp } from 'firebase/app'
import { getFirestore } from 'firebase/firestore'
import { getAuth } from 'firebase/auth'
import { getStorage } from 'firebase/storage'
import { getDatabase } from 'firebase/database'
import { getAnalytics } from 'firebase/analytics'
import { getPerformance } from 'firebase/performance'

const firebaseConfig = {
  apiKey: 'AIzaSyB_Z0bEP5VY-DFTqdv2RmzGpLWZFpzJbXE',
  authDomain: 'ket.academy',
  databaseURL:
    'https://ket-academy-web-app-id-default-rtdb.europe-west1.firebasedatabase.app',
  projectId: 'ket-academy-web-app-id',
  storageBucket: 'ket-academy-web-app-id.appspot.com',
  messagingSenderId: '684482815070',
  appId: '1:684482815070:web:7d7f453f7724dc85fac0cf',
  measurementId: 'G-PGY589TP52',
}

// Initialize Firebase
const app = initializeApp(firebaseConfig)

// Initialize services
export const db = getFirestore(app)
export const auth = getAuth(app)
export const storage = getStorage(app)
export const rtdb = getDatabase(app)
export const analytics = getAnalytics(app)
export const perf = getPerformance(app)

export default app
