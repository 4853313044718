import React, { useState, useEffect, useCallback, useRef } from 'react'
import { useLocation, useParams, useNavigate } from 'react-router-dom'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faPaperPlane,
  faBars,
  faSpinner,
  faListUl,
} from '@fortawesome/free-solid-svg-icons'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import { useAuth } from '../../../utils/firebase/auth'
import { generateAIResponse } from '../../../utils/openai'
import agentPrompts from '../../../utils/openai/agentPrompts'
import { rateLimiter } from '../../../utils/chat/errorHandling'
import { ErrorMessages } from '../../../utils/chat/errorHandling'
import { ChatErrors } from '../../../utils/chat/errorHandling'
import {
  createNewChat,
  updateChatMessages,
  getUserChats,
} from '../../../utils/firebase/chat'

const ChatPage = () => {
  const { agentSlug } = useParams()
  const location = useLocation()
  const navigate = useNavigate()
  const { authUser } = useAuth()
  const [messages, setMessages] = useState([])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [streamingMessage, setStreamingMessage] = useState('')
  const messagesEndRef = useRef(null)
  const [currentChatId, setCurrentChatId] = useState(null)
  const [chatHistory, setChatHistory] = useState([])
  const [showHistory, setShowHistory] = useState(false)
  const [isLoadingHistory, setIsLoadingHistory] = useState(true)
  const [isSummarizing, setIsSummarizing] = useState(false)

  const { agentType, icon } = location.state || {}

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages, streamingMessage])

  // Memoize the getInitialMessage function
  const getInitialMessage = useCallback(() => {
    switch (agentSlug) {
      case 'study-partner':
        return "Hello! I'm your AI Study Partner, your virtual friend for academic support. How can I help you with your studies, homework, or exam preparation today?"
      case 'virtual-ambassador':
        return "Hi! I'm your Virtual AI Ambassador. I'm here to help you with your studies and answer any questions you have. What would you like to know?"
      case 'academic-counselor':
        return "Hi! I'm your AI Academic Counselor. I can help you with study techniques, focus strategies, and career planning. How can I assist you today?"
      default:
        return "Hello! I'm your Scholarship & College AI Assistant. I can guide you through scholarship searches and college admissions worldwide. How can I help with your educational journey?"
    }
  }, [agentSlug])

  useEffect(() => {
    if (!authUser) {
      navigate('/account/login')
      return
    }

    // Set initial welcome message based on agent type
    setMessages([
      {
        type: 'bot',
        content: getInitialMessage(),
      },
    ])
  }, [authUser, navigate, agentSlug, getInitialMessage])

  useEffect(() => {
    const loadChatHistory = async () => {
      if (authUser) {
        setIsLoadingHistory(true)
        try {
          const chats = await getUserChats(authUser.uid)
          setChatHistory(chats)
        } catch (error) {
          console.error('Error loading chat history:', error)
        } finally {
          setIsLoadingHistory(false)
        }
      }
    }
    loadChatHistory()
  }, [authUser])

  const handleNewChat = async () => {
    setCurrentChatId(null)
    setMessages([
      {
        type: 'bot',
        content: getInitialMessage(),
      },
    ])
  }

  const handleSendMessage = async (input) => {
    try {
      // Get agent type from URL params
      const agentType = agentSlug.replace(/-/g, ' ')
      const systemPrompt = agentPrompts[agentSlug]?.content || ''

      if (!currentChatId) {
        // Create new chat session
        const chatId = await createNewChat(
          authUser.uid,
          agentType,
          'Hello! How can I help you today?'
        )
        setCurrentChatId(chatId)
      }

      // Add user message
      const userMessage = {
        type: 'user',
        content: input,
        timestamp: Date.now(),
      }

      setMessages((prev) => [...prev, userMessage])
      setIsLoading(true)

      // Add an empty bot message that will be updated during streaming
      setMessages((prev) => [
        ...prev,
        {
          type: 'bot',
          content: '',
          timestamp: Date.now(),
        },
      ])

      let fullResponse = ''
      // Define onStream callback
      const onStream = (chunk) => {
        fullResponse += chunk
        setStreamingMessage(fullResponse)
      }

      // Generate AI response with conversation history
      const response = await generateAIResponse(
        input,
        onStream,
        systemPrompt,
        messages
      )

      // Update the last message with complete response
      setMessages((prev) => {
        const newMessages = [...prev]
        newMessages[newMessages.length - 1].content = response
        return newMessages
      })

      // Update chat session in Firebase
      await updateChatMessages(currentChatId, [
        ...messages,
        userMessage,
        { type: 'bot', content: response, timestamp: Date.now() },
      ])
    } catch (error) {
      console.error('Error in chat:', error)
      // Handle error appropriately
    } finally {
      setIsLoading(false)
      setStreamingMessage('')
    }
  }

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!input.trim()) return

    const userId = authUser?.uid
    if (!userId) {
      navigate('/account/login')
      return
    }

    setIsLoading(true)
    try {
      await handleSendMessage(input)
      setInput('')
    } catch (error) {
      const errorMessage =
        ErrorMessages[error.message] || ErrorMessages[ChatErrors.API_ERROR]

      setMessages((prev) => [
        ...prev,
        {
          type: 'bot',
          content: errorMessage,
          isError: true,
        },
      ])

      if (error.message === ChatErrors.RATE_LIMIT) {
        const remainingRequests = rateLimiter.getRemainingRequests(userId)
        const timeUntilReset = Math.ceil(
          rateLimiter.getTimeUntilReset(userId) / 1000
        )

        setMessages((prev) => [
          ...prev,
          {
            type: 'bot',
            content: `You have ${remainingRequests} messages remaining. Reset in ${timeUntilReset} seconds.`,
            isError: true,
          },
        ])
      }
    } finally {
      setIsLoading(false)
    }
  }

  const renderMessage = (content) => {
    return (
      <ReactMarkdown
        remarkPlugins={[remarkGfm]}
        rehypePlugins={[rehypeRaw]}
        className="prose prose-sm max-w-none prose-pre:bg-gray-800 prose-pre:text-white prose-pre:p-4 prose-pre:rounded-lg prose-code:text-primary prose-headings:text-gray-800 prose-p:text-gray-700 prose-strong:text-primary prose-a:text-primary hover:prose-a:text-primary/80"
      >
        {content}
      </ReactMarkdown>
    )
  }

  const summarizeConversation = async (messages) => {
    try {
      const summary = await generateAIResponse(
        'Please provide a brief summary of our conversation so far, highlighting key topics discussed.',
        () => {},
        '',
        messages
      )
      return summary
    } catch (error) {
      console.error('Error summarizing conversation:', error)
      return ''
    }
  }

  const handleSummarize = async () => {
    if (messages.length < 2) {
      return // Don't summarize if there's not enough conversation
    }

    setIsSummarizing(true)
    try {
      const summary = await summarizeConversation(messages)
      setMessages((prev) => [
        ...prev,
        {
          type: 'bot',
          content: `**Conversation Summary:**\n\n${summary}`,
          timestamp: Date.now(),
        },
      ])
    } catch (error) {
      console.error('Error summarizing conversation:', error)
    } finally {
      setIsSummarizing(false)
    }
  }

  return (
    <div className="flex h-screen bg-gray-50">
      {/* Chat History Sidebar */}
      <div
        className={`${
          showHistory ? 'w-64' : 'w-0'
        } bg-white border-r transition-all duration-300 overflow-hidden`}
      >
        <div className="p-4">
          <button
            onClick={handleNewChat}
            className="w-full bg-primary text-white px-4 py-2 rounded-lg mb-4"
          >
            New Chat
          </button>
          <div className="space-y-2">
            {isLoadingHistory ? (
              <div className="text-center py-4">
                <FontAwesomeIcon
                  icon={faSpinner}
                  spin
                  className="text-primary"
                />
                <p className="text-sm text-gray-500 mt-2">
                  Loading chat history...
                </p>
              </div>
            ) : chatHistory.length === 0 ? (
              <p className="text-sm text-gray-500 text-center py-4">
                No chat history yet
              </p>
            ) : (
              chatHistory.map((chat) => (
                <button
                  key={chat.id}
                  onClick={() => {
                    setCurrentChatId(chat.id)
                    setMessages(chat.messages)
                  }}
                  className={`w-full text-left p-2 rounded-lg hover:bg-gray-100 ${
                    currentChatId === chat.id ? 'bg-gray-100' : ''
                  }`}
                >
                  <p className="text-sm font-medium truncate">
                    {chat.agentType}
                  </p>
                  <p className="text-xs text-gray-500 truncate">
                    {new Date(chat.lastUpdated?.toDate()).toLocaleDateString()}
                  </p>
                </button>
              ))
            )}
          </div>
        </div>
      </div>

      {/* Main Chat Area */}
      <div className="flex-1 flex flex-col">
        {/* Modify header to include history toggle */}
        <div className="bg-primary px-4 py-4 flex items-center sticky top-0 z-10 shadow-lg">
          <button
            onClick={() => setShowHistory(!showHistory)}
            className="text-white mr-4 hover:bg-primary-700 p-2 rounded-full transition-colors"
          >
            <FontAwesomeIcon icon={faBars} />
          </button>
          <button
            onClick={() => navigate('/k-ai')}
            className="text-white mr-4 hover:bg-primary-700 p-2 rounded-full transition-colors"
          >
            <FontAwesomeIcon icon={faArrowLeft} />
          </button>
          <button
            onClick={handleSummarize}
            disabled={messages.length < 2 || isSummarizing}
            className="text-white mr-4 hover:bg-primary-700 p-2 rounded-full transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            title="Summarize conversation"
          >
            {isSummarizing ? (
              <FontAwesomeIcon icon={faSpinner} spin />
            ) : (
              <FontAwesomeIcon icon={faListUl} />
            )}
          </button>
          <div className="flex items-center space-x-4">
            <div className="bg-white/10 p-3 rounded-xl">
              <FontAwesomeIcon icon={icon} className="text-white text-xl" />
            </div>
            <div>
              <h1 className="text-white font-semibold text-lg">{agentType}</h1>
              <p className="text-white/70 text-sm">AI Assistant</p>
            </div>
          </div>
        </div>

        {/* Chat Messages */}
        <div className="flex-1 overflow-y-auto p-4 space-y-6">
          {messages.map((message, index) => (
            <div
              key={index}
              className={`flex ${
                message.type === 'user' ? 'justify-end' : 'justify-start'
              }`}
            >
              <div
                className={`max-w-[85%] p-4 rounded-2xl ${
                  message.type === 'user'
                    ? 'bg-primary text-white rounded-br-none shadow-md'
                    : 'bg-white shadow-md rounded-bl-none'
                }`}
              >
                {message.type === 'user' ? (
                  <p className="text-sm md:text-base">{message.content}</p>
                ) : (
                  <div className="text-sm md:text-base">
                    {index === messages.length - 1 && message.type === 'bot'
                      ? renderMessage(streamingMessage || message.content)
                      : renderMessage(message.content)}
                  </div>
                )}
              </div>
            </div>
          ))}
          {isLoading && (
            <div className="flex justify-start">
              <div className="bg-white p-4 rounded-xl shadow-sm">
                <div className="flex space-x-2">
                  <div className="w-2 h-2 bg-primary/40 rounded-full animate-bounce" />
                  <div className="w-2 h-2 bg-primary/40 rounded-full animate-bounce delay-100" />
                  <div className="w-2 h-2 bg-primary/40 rounded-full animate-bounce delay-200" />
                </div>
              </div>
            </div>
          )}
          <div ref={messagesEndRef} />
        </div>

        {/* Input Area */}
        <form
          onSubmit={handleSubmit}
          className="p-4 bg-white border-t shadow-lg"
        >
          <div className="flex gap-3 max-w-4xl mx-auto">
            <input
              type="text"
              value={input}
              onChange={(e) => setInput(e.target.value)}
              placeholder="Type your message..."
              className="flex-1 p-4 border rounded-2xl focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 text-gray-900 placeholder:text-gray-500"
            />
            <button
              type="submit"
              disabled={isLoading}
              className="bg-primary text-white p-4 rounded-2xl hover:bg-primary/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            >
              <FontAwesomeIcon
                icon={faPaperPlane}
                className={`w-5 h-5 ${isLoading ? 'opacity-50' : ''}`}
              />
            </button>
          </div>
          <p className="text-xs text-gray-500 mt-3 text-center max-w-4xl mx-auto">
            K-AI is still in development and might make mistakes. We're
            continuously improving it.
          </p>
        </form>
      </div>
    </div>
  )
}

export default ChatPage
