import { debounce } from 'lodash'
import React, { useCallback, useEffect, useMemo, useState } from 'react'
import { Search } from 'react-feather'
import courseImage from '../../../assets/images/library/Courses.png'
import BreadCrumb from '../../BreadCrumb'
import DashboardLibrary from '../Library'
import {
  trackPageView,
  trackResourceView,
  trackResourceSearch,
  trackResourceFilter,
  trackTabChange,
} from '../../../utils/analytics'

const Resources = function () {
  const [searchQuery, setSearchQuery] = useState('')
  const [isSearching, setIsSearching] = useState(false)
  const [filters, setFilters] = useState({
    classType: [],
    category: [],
    subject: [],
  })
  const [selectedResources, setSelectedResources] = useState([])
  const [currentPage, setCurrentPage] = useState(1)
  const [filteredResources, setFilteredResources] = useState([])
  const [activeTab, setActiveTab] = useState('library')

  // Add this constant for pagination
  const resourcesPerPage = 10

  // Memoize the resources array
  const resources = useMemo(
    () => [
      {
        id: 1,
        title: 'Advanced Mathematics',
        duration: '48 Hours',
        instructor: 'Dr. Sarah Chen',
        studentsEnrolled: '+ 250',
        image: courseImage,
        subject: 'Mathematics',
        classType: 'Upper Sixth',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 2,
        title: 'Chemistry Fundamentals',
        duration: '36 Hours',
        instructor: 'Prof. Michael Brown',
        studentsEnrolled: '+ 180',
        image: courseImage,
        subject: 'Chemistry',
        classType: 'Form 5',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 3,
        title: 'Biology and Life Sciences',
        duration: '42 Hours',
        instructor: 'Dr. Emily Watson',
        studentsEnrolled: '+ 200',
        image: courseImage,
        subject: 'Biology',
        classType: 'Lower Sixth',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 4,
        title: 'World Literature',
        duration: '30 Hours',
        instructor: 'Prof. James Wilson',
        studentsEnrolled: '+ 150',
        image: courseImage,
        subject: 'Literature',
        classType: 'Form 4',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 5,
        title: 'Modern History',
        duration: '38 Hours',
        instructor: 'Dr. Eleanor Pena',
        studentsEnrolled: '+ 175',
        image: courseImage,
        subject: 'History',
        classType: 'Form 3',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 6,
        title: 'English Language Skills',
        duration: '45 Hours',
        instructor: 'Sarah Thompson',
        studentsEnrolled: '+ 300',
        image: courseImage,
        subject: 'English Language',
        classType: 'Form 2',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 7,
        title: 'Physics Mastery',
        duration: '50 Hours',
        instructor: 'Dr. Robert Chen',
        studentsEnrolled: '+ 160',
        image: courseImage,
        subject: 'Physics',
        classType: 'Form 1',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 8,
        title: 'Food Science',
        duration: '32 Hours',
        instructor: 'Chef Maria Garcia',
        studentsEnrolled: '+ 120',
        image: courseImage,
        subject: 'Food & Nutrition',
        classType: 'Form 5',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 9,
        title: 'Advanced Chemistry Lab',
        duration: '40 Hours',
        instructor: 'Dr. David Lee',
        studentsEnrolled: '+ 90',
        image: courseImage,
        subject: 'Chemistry',
        classType: 'Form 5',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 10,
        title: 'Literature Analysis',
        duration: '35 Hours',
        instructor: 'Prof. Amanda White',
        studentsEnrolled: '+ 140',
        image: courseImage,
        subject: 'Literature',
        classType: 'Form 4',
        category: 'PDF',
        level: 'Ordinary',
      },
      {
        id: 11,
        title: 'Mathematical Problem Solving',
        duration: '44 Hours',
        instructor: 'Dr. John Martinez',
        studentsEnrolled: '+ 220',
        image: courseImage,
        subject: 'Mathematics',
        classType: 'Form 3',
        category: 'Video',
        level: 'Advanced',
      },
      {
        id: 12,
        title: 'Biology Research Methods',
        duration: '38 Hours',
        instructor: 'Prof. Lisa Anderson',
        studentsEnrolled: '+ 110',
        image: courseImage,
        subject: 'Biology',
        classType: 'Form 2',
        category: 'PDF',
        level: 'Ordinary',
      },
    ],
    []
  )

  // Define the debounced function first
  const debouncedSearch = debounce((query) => {
    setIsSearching(true)
    const searchResults = resources.filter(
      (resource) =>
        resource.title.toLowerCase().includes(query.toLowerCase()) ||
        resource.instructor.toLowerCase().includes(query.toLowerCase())
    )
    setFilteredResources(searchResults)
    setIsSearching(false)
  }, 500)

  // Then use it in handleSearch
  const handleSearch = useCallback(
    (query) => {
      debouncedSearch(query)
      trackResourceSearch(query)
    },
    [debouncedSearch]
  )

  // Add useEffect for page view tracking
  useEffect(() => {
    trackPageView('resources')
  }, [])

  // Update tab change handler
  const handleTabChange = (tab) => {
    setActiveTab(tab)
    trackTabChange(tab)
  }

  // Update filter change handler
  const handleFilterChange = (filterType, value) => {
    setFilters((prev) => ({
      ...prev,
      [filterType]: prev[filterType].includes(value)
        ? prev[filterType].filter((item) => item !== value)
        : [...prev[filterType], value],
    }))
    trackResourceFilter(filterType, value)
  }

  // Update resource click handler
  const handleResourceClick = (resource) => {
    setSelectedResources((prev) =>
      prev.includes(resource.id)
        ? prev.filter((i) => i !== resource.id)
        : [...prev, resource.id]
    )
    trackResourceView(resource.id, resource.title, resource.category)
  }

  useEffect(() => {
    if (!searchQuery) {
      const filtered = resources.filter((resource) => {
        const matchesClassType =
          filters.classType.length === 0 ||
          filters.classType.includes(resource.classType)

        const matchesCategory =
          filters.category.length === 0 ||
          filters.category.includes(resource.category)

        const matchesSubject =
          filters.subject.length === 0 ||
          filters.subject.includes(resource.subject)

        return matchesClassType && matchesCategory && matchesSubject
      })

      setFilteredResources(filtered)
    }
  }, [filters, resources, searchQuery])

  const indexOfLastResource = currentPage * resourcesPerPage
  const indexOfFirstResource = indexOfLastResource - resourcesPerPage
  const currentResources = filteredResources.slice(
    indexOfFirstResource,
    indexOfLastResource
  )

  return (
    <main className="p-4 md:p-8">
      <BreadCrumb
        title={'Learning Resources'}
        slugs={[
          { display: 'Dashboard', to: '/dashboard/home', active: false },
          { display: 'Resources', to: '/dashboard/resources', active: true },
        ]}
      />

      <div className="bg-gradient-to-r from-blue-600 to-blue-800 text-white p-8 rounded-xl mb-8">
        <div className="flex flex-col md:flex-row justify-between items-center gap-6">
          <div>
            <h1 className="text-3xl font-bold mb-2">
              Educational Resources Hub
            </h1>
            <p className="text-blue-100">
              Access all your learning materials in one place
            </p>
          </div>
        </div>
      </div>

      {/* Resource Categories Grid */}
      <div className="grid grid-cols-3 md:grid-cols-6 gap-4 mb-8">
        {/* Library */}
        <div
          onClick={() => handleTabChange('library')}
          className={`cursor-pointer p-4 rounded-xl border-2 transition-all duration-300 ${
            activeTab === 'library'
              ? 'border-blue-600 bg-blue-50'
              : 'border-gray-200 hover:border-blue-400 hover:bg-blue-50'
          }`}
        >
          <div className="flex flex-col items-center gap-2">
            <div className="w-10 h-10 bg-green-100 rounded-lg flex items-center justify-center">
              <i className="fas fa-library text-green-600 text-lg"></i>
            </div>
            <h3 className="text-sm font-semibold text-gray-800 text-center">
              Library
            </h3>
          </div>
        </div>

        {/* My Resources */}
        <div
          onClick={() => handleTabChange('resources')}
          className={`cursor-pointer p-4 rounded-xl border-2 transition-all duration-300 ${
            activeTab === 'resources'
              ? 'border-blue-600 bg-blue-50'
              : 'border-gray-200 hover:border-blue-400 hover:bg-blue-50'
          }`}
        >
          <div className="flex flex-col items-center gap-2">
            <div className="w-10 h-10 bg-blue-100 rounded-lg flex items-center justify-center">
              <i className="fas fa-book text-blue-600 text-lg"></i>
            </div>
            <h3 className="text-sm font-semibold text-gray-800 text-center">
              My Resources
            </h3>
          </div>
        </div>

        {/* GCE Questions */}
        <div
          onClick={() => handleTabChange('gce-questions')}
          className={`cursor-pointer p-4 rounded-xl border-2 transition-all duration-300 ${
            activeTab === 'gce-questions'
              ? 'border-blue-600 bg-blue-50'
              : 'border-gray-200 hover:border-blue-400 hover:bg-blue-50'
          }`}
        >
          <div className="flex flex-col items-center gap-2">
            <div className="w-10 h-10 bg-purple-100 rounded-lg flex items-center justify-center">
              <i className="fas fa-question text-purple-600 text-lg"></i>
            </div>
            <h3 className="text-sm font-semibold text-gray-800 text-center">
              GCE Questions
            </h3>
          </div>
        </div>

        {/* GCE Solutions */}
        <div
          onClick={() => handleTabChange('gce-solutions')}
          className={`cursor-pointer p-4 rounded-xl border-2 transition-all duration-300 ${
            activeTab === 'gce-solutions'
              ? 'border-blue-600 bg-blue-50'
              : 'border-gray-200 hover:border-blue-400 hover:bg-blue-50'
          }`}
        >
          <div className="flex flex-col items-center gap-2">
            <div className="w-10 h-10 bg-orange-100 rounded-lg flex items-center justify-center">
              <i className="fas fa-lightbulb text-orange-600 text-lg"></i>
            </div>
            <h3 className="text-sm font-semibold text-gray-800 text-center">
              GCE Solutions
            </h3>
          </div>
        </div>

        {/* Summary Notes */}
        <div
          onClick={() => handleTabChange('notes')}
          className={`cursor-pointer p-4 rounded-xl border-2 transition-all duration-300 ${
            activeTab === 'notes'
              ? 'border-blue-600 bg-blue-50'
              : 'border-gray-200 hover:border-blue-400 hover:bg-blue-50'
          }`}
        >
          <div className="flex flex-col items-center gap-2">
            <div className="w-10 h-10 bg-red-100 rounded-lg flex items-center justify-center">
              <i className="fas fa-sticky-note text-red-600 text-lg"></i>
            </div>
            <h3 className="text-sm font-semibold text-gray-800 text-center">
              Summary Notes
            </h3>
          </div>
        </div>

        {/* Textbooks */}
        <div
          onClick={() => handleTabChange('textbooks')}
          className={`cursor-pointer p-4 rounded-xl border-2 transition-all duration-300 ${
            activeTab === 'textbooks'
              ? 'border-blue-600 bg-blue-50'
              : 'border-gray-200 hover:border-blue-400 hover:bg-blue-50'
          }`}
        >
          <div className="flex flex-col items-center gap-2">
            <div className="w-10 h-10 bg-yellow-100 rounded-lg flex items-center justify-center">
              <i className="fas fa-book-open text-yellow-600 text-lg"></i>
            </div>
            <h3 className="text-sm font-semibold text-gray-800 text-center">
              Textbooks
            </h3>
          </div>
        </div>
      </div>

      {/* Content Area */}
      <div className="mt-8">
        {activeTab === 'resources' ? (
          <>
            {/* Add Search Bar */}
            <div className="flex flex-col md:flex-row justify-between items-start md:items-center gap-4 mb-6">
              <h1 className="text-2xl font-bold text-blue-900">
                Browse Resources to keep yourself on the track
              </h1>
              <div className="relative w-full md:w-96">
                <input
                  type="text"
                  value={searchQuery}
                  onChange={(e) => {
                    setSearchQuery(e.target.value)
                    handleSearch(e.target.value)
                  }}
                  placeholder="Search resources..."
                  className="w-full pl-12 pr-4 py-3 border-2 border-gray-200 rounded-xl focus:ring-2 focus:ring-blue-500 focus:border-transparent transition-all duration-300"
                />
                {isSearching ? (
                  <div className="absolute right-4 top-1/2 transform -translate-y-1/2">
                    <div className="animate-spin h-5 w-5 border-2 border-blue-500 border-t-transparent rounded-full" />
                  </div>
                ) : (
                  <Search
                    className="absolute left-4 top-1/2 transform -translate-y-1/2 text-gray-400"
                    size={20}
                  />
                )}
              </div>
            </div>

            {/* Conditionally render filters only when searchQuery is empty */}
            {!searchQuery && (
              <div className="bg-white rounded-xl shadow-sm p-6 mb-8">
                <div className="flex items-center justify-between mb-6">
                  <h2 className="text-xl font-semibold text-gray-800">
                    Filters
                  </h2>
                  <button
                    onClick={() =>
                      setFilters({ classType: [], category: [], subject: [] })
                    }
                    className="text-sm text-blue-600 hover:text-blue-800 hover:underline transition-colors"
                  >
                    Clear all filters
                  </button>
                </div>
                <div className="grid grid-cols-1 md:grid-cols-3 gap-6">
                  {/* Class Types */}
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">
                      Class Type
                    </label>
                    <select
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
                      value={filters.classType}
                      onChange={(e) =>
                        handleFilterChange('classType', e.target.value)
                      }
                    >
                      <option value="">All Class Types</option>
                      {[
                        'Upper Sixth',
                        'Lower Sixth',
                        'Form 5',
                        'Form 4',
                        'Form 3',
                        'Form 2',
                        'Form 1',
                      ].map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Category */}
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">
                      Category
                    </label>
                    <select
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
                      value={filters.category}
                      onChange={(e) =>
                        handleFilterChange('category', e.target.value)
                      }
                    >
                      <option value="">All Categories</option>
                      {['PDF', 'Video'].map((type) => (
                        <option key={type} value={type}>
                          {type}
                        </option>
                      ))}
                    </select>
                  </div>

                  {/* Subject */}
                  <div className="space-y-2">
                    <label className="text-sm font-medium text-gray-700">
                      Subject
                    </label>
                    <select
                      className="w-full p-3 border border-gray-300 rounded-lg focus:ring-2 focus:ring-blue-500 focus:border-transparent bg-white"
                      value={filters.subject}
                      onChange={(e) =>
                        handleFilterChange('subject', e.target.value)
                      }
                    >
                      <option value="">All Subjects</option>
                      {[
                        'Mathematics',
                        'Chemistry',
                        'Biology',
                        'Literature',
                        'History',
                        'English Language',
                        'Physics',
                        'Food & Nutrition',
                      ].map((subject) => (
                        <option key={subject} value={subject}>
                          {subject}
                        </option>
                      ))}
                    </select>
                  </div>
                </div>
              </div>
            )}

            {/* Update Resources Table */}
            <div className="bg-white rounded-lg shadow overflow-hidden">
              {isSearching ? (
                <div className="p-12 text-center">
                  <div className="animate-spin rounded-full h-16 w-16 border-b-2 border-blue-900 mx-auto"></div>
                  <p className="mt-4 text-gray-600 text-lg">
                    Loading your resources...
                  </p>
                </div>
              ) : resources.length === 0 ? (
                <div className="p-12 text-center">
                  <div className="mx-auto w-24 h-24 bg-gray-100 rounded-full flex items-center justify-center mb-4">
                    <Search className="text-gray-400" size={32} />
                  </div>
                  <p className="text-gray-600 text-lg">
                    No resources found matching your criteria.
                  </p>
                  <button
                    onClick={() =>
                      setFilters({ classType: [], category: [], subject: [] })
                    }
                    className="mt-4 text-blue-600 hover:text-blue-800"
                  >
                    Clear all filters
                  </button>
                </div>
              ) : (
                <>
                  <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                    {currentResources.map((resource) => (
                      <div
                        key={resource.id}
                        className="bg-white rounded-xl shadow-sm hover:shadow-xl transition-all duration-300 border border-gray-100"
                      >
                        <div className="relative pb-48 overflow-hidden rounded-t-xl">
                          <img
                            src={resource.image}
                            alt={resource.title}
                            className="absolute h-full w-full object-cover transform hover:scale-110 transition-transform duration-500"
                          />
                          <div className="absolute top-4 right-4">
                            <div
                              className={`px-3 py-1 rounded-full text-xs font-semibold ${
                                resource.level === 'Advanced'
                                  ? 'bg-blue-100 text-blue-800'
                                  : 'bg-green-100 text-green-800'
                              }`}
                            >
                              {resource.level}
                            </div>
                          </div>
                        </div>
                        <div className="p-6">
                          <div className="flex justify-between items-start mb-4">
                            <h3 className="text-lg font-semibold text-gray-800 hover:text-blue-600 transition-colors">
                              {resource.title}
                            </h3>
                            <input
                              type="checkbox"
                              checked={selectedResources.includes(resource.id)}
                              onChange={() => handleResourceClick(resource)}
                              className="w-5 h-5 text-blue-600 rounded border-gray-300 focus:ring-blue-500"
                            />
                          </div>
                          <div className="flex items-center gap-4 mb-4">
                            <div className="flex items-center text-sm text-gray-600">
                              <i className="far fa-clock mr-2"></i>
                              {resource.duration}
                            </div>
                            <div className="flex items-center text-sm text-gray-600">
                              <i className="far fa-user mr-2"></i>
                              {resource.instructor}
                            </div>
                          </div>
                          <div className="flex justify-between items-center">
                            <span className="text-sm text-gray-500">
                              <i className="fas fa-users mr-2"></i>
                              {resource.studentsEnrolled} enrolled
                            </span>
                            <button className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 transform hover:-translate-y-1 transition-all duration-300">
                              Enroll Now
                            </button>
                          </div>
                        </div>
                      </div>
                    ))}
                  </div>

                  {/* Add Pagination */}
                  <div className="bg-white rounded-xl shadow-sm mt-8">
                    <div className="px-6 py-4 flex items-center justify-between border-t border-gray-200">
                      <div className="text-sm text-gray-700">
                        Showing{' '}
                        <span className="font-medium">
                          {(currentPage - 1) * resourcesPerPage + 1}
                        </span>{' '}
                        to{' '}
                        <span className="font-medium">
                          {Math.min(
                            currentPage * resourcesPerPage,
                            resources.length
                          )}
                        </span>{' '}
                        of{' '}
                        <span className="font-medium">{resources.length}</span>{' '}
                        results
                      </div>
                      <div className="flex gap-2">
                        <button
                          onClick={() =>
                            setCurrentPage((p) => Math.max(1, p - 1))
                          }
                          disabled={currentPage === 1}
                          className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                        >
                          Previous
                        </button>
                        <button
                          onClick={() => setCurrentPage((p) => p + 1)}
                          disabled={
                            currentPage * resourcesPerPage >= resources.length
                          }
                          className="px-4 py-2 bg-blue-600 text-white rounded-lg hover:bg-blue-700 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
                        >
                          Next
                        </button>
                      </div>
                    </div>
                  </div>
                </>
              )}
            </div>
          </>
        ) : activeTab === 'library' ? (
          <div className="bg-white rounded-lg shadow-sm">
            <DashboardLibrary />
          </div>
        ) : activeTab === 'gce-questions' ? (
          // GCE Questions Content
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-bold text-gray-800">
                GCE Questions
              </h2>
              <div className="relative w-64">
                <input
                  type="text"
                  placeholder="Search past questions..."
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <Search
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={20}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Placeholder for GCE Questions content - To be implemented */}
              <div className="p-6 border rounded-lg bg-gray-50 text-center">
                <p className="text-gray-600">
                  GCE Questions content coming soon...
                </p>
              </div>
            </div>
          </div>
        ) : activeTab === 'gce-solutions' ? (
          // GCE Solutions Content
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-bold text-gray-800">
                GCE Solutions
              </h2>
              <div className="relative w-64">
                <input
                  type="text"
                  placeholder="Search solutions..."
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <Search
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={20}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Placeholder for GCE Solutions content - To be implemented */}
              <div className="p-6 border rounded-lg bg-gray-50 text-center">
                <p className="text-gray-600">
                  GCE Solutions content coming soon...
                </p>
              </div>
            </div>
          </div>
        ) : (
          // Summary Notes & Textbooks Content
          <div className="bg-white rounded-lg shadow-sm p-6">
            <div className="flex justify-between items-center mb-8">
              <h2 className="text-2xl font-bold text-gray-800">
                Summary Notes & Textbooks
              </h2>
              <div className="relative w-64">
                <input
                  type="text"
                  placeholder="Search notes..."
                  className="w-full pl-10 pr-4 py-2 border rounded-lg focus:ring-2 focus:ring-blue-500"
                />
                <Search
                  className="absolute left-3 top-1/2 transform -translate-y-1/2 text-gray-400"
                  size={20}
                />
              </div>
            </div>
            <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
              {/* Placeholder for Notes content - To be implemented */}
              <div className="p-6 border rounded-lg bg-gray-50 text-center">
                <p className="text-gray-600">
                  Summary Notes & Textbooks content coming soon...
                </p>
              </div>
            </div>
          </div>
        )}
      </div>
    </main>
  )
}

export default Resources
