export const ChatErrors = {
  RATE_LIMIT: 'RATE_LIMIT',
  API_ERROR: 'API_ERROR',
  NETWORK_ERROR: 'NETWORK_ERROR',
  AUTHENTICATION_ERROR: 'AUTHENTICATION_ERROR',
  VALIDATION_ERROR: 'VALIDATION_ERROR',
}

export const ErrorMessages = {
  [ChatErrors.RATE_LIMIT]:
    "You've reached the maximum number of messages. Please wait a moment before trying again.",
  [ChatErrors.API_ERROR]:
    'Our AI service is currently experiencing issues. Please try again later.',
  [ChatErrors.NETWORK_ERROR]:
    'Unable to connect to the chat service. Please check your internet connection.',
  [ChatErrors.AUTHENTICATION_ERROR]:
    'Your session has expired. Please sign in again.',
  [ChatErrors.VALIDATION_ERROR]: 'Invalid input. Please try again.',
}

// Rate limiting configuration
const RATE_LIMIT_WINDOW = 60000 // 1 minute
const MAX_REQUESTS = 10 // Maximum requests per minute

class RateLimiter {
  constructor() {
    this.requests = new Map() // userId -> [timestamps]
  }

  isRateLimited(userId) {
    if (!this.requests.has(userId)) {
      this.requests.set(userId, [])
    }

    const userRequests = this.requests.get(userId)
    const now = Date.now()

    // Remove timestamps outside the current window
    const validRequests = userRequests.filter(
      (timestamp) => now - timestamp < RATE_LIMIT_WINDOW
    )

    this.requests.set(userId, validRequests)

    if (validRequests.length >= MAX_REQUESTS) {
      return true
    }

    validRequests.push(now)
    return false
  }

  getRemainingRequests(userId) {
    const userRequests = this.requests.get(userId) || []
    const now = Date.now()
    const validRequests = userRequests.filter(
      (timestamp) => now - timestamp < RATE_LIMIT_WINDOW
    )
    return MAX_REQUESTS - validRequests.length
  }

  getTimeUntilReset(userId) {
    const userRequests = this.requests.get(userId) || []
    if (userRequests.length === 0) return 0

    const oldestRequest = Math.min(...userRequests)
    return RATE_LIMIT_WINDOW - (Date.now() - oldestRequest)
  }
}

export const rateLimiter = new RateLimiter()
