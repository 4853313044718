import React, { useState, useRef, useEffect } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import {
  faArrowLeft,
  faPaperPlane,
  faSpinner,
  faGraduationCap,
  faCompass,
  faUniversity,
  faChalkboardTeacher,
  faCommentDots,
} from '@fortawesome/free-solid-svg-icons'
import {
  faWhatsapp,
  faFacebook,
  faInstagram,
  faLinkedin,
  faYoutube,
} from '@fortawesome/free-brands-svg-icons'
import ReactMarkdown from 'react-markdown'
import remarkGfm from 'remark-gfm'
import rehypeRaw from 'rehype-raw'
import BreadCrumb from '../../BreadCrumb'
//import { DUMMY_PROFILE_IMAGE } from '../../../utils/constants'
import { AIAgent } from '../../../pages/site/KAI'

const Chats = () => {
  const [selectedAgent, setSelectedAgent] = useState(null)
  const [messages, setMessages] = useState([])
  const [inputMessage, setInputMessage] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const messagesEndRef = useRef(null)

  // AI Assistants Section
  const aiAgents = [
    {
      title: 'Study Partner',
      description:
        'Your personal virtual study buddy for homework help and exam preparation.',
      icon: faGraduationCap,
    },
    {
      title: 'Virtual Ambassador',
      description: 'Get help with your studies and academic questions.',
      icon: faChalkboardTeacher,
    },
    {
      title: 'Academic Counselor',
      description: 'Guidance on study techniques and career planning.',
      icon: faCompass,
    },
    {
      title: 'Scholarship Assistant',
      description: 'Help with finding and applying for scholarships worldwide.',
      icon: faUniversity,
    },
  ]

  // Student Communities Section
  const communities = [
    {
      id: 'science-al',
      name: 'Science A-Level',
      members: 245,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      description: 'Advanced Level Science subjects discussion and support',
    },
    {
      id: 'arts-al',
      name: 'Arts A-Level',
      members: 178,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      description: 'Advanced Level Arts subjects discussion and support',
    },
    {
      id: 'science-ol',
      name: 'Science O-Level',
      members: 312,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      description: 'Ordinary Level Science subjects discussion and support',
    },
    {
      id: 'arts-ol',
      name: 'Arts O-Level',
      members: 256,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      description: 'Ordinary Level Arts subjects discussion and support',
    },
    {
      id: 'counseling',
      name: 'Student Counseling',
      members: 189,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      description: 'Academic and career guidance support',
    },
    {
      id: 'scholarship',
      name: 'Scholarship Hub',
      members: 423,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      description: 'Scholarship opportunities and application support',
    },
    {
      id: 'community-project',
      name: 'Community Projects',
      members: 167,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      description: 'Volunteer and community service initiatives',
    },
  ]

  // Social Media Links
  const socialLinks = [
    {
      platform: 'WhatsApp Community',
      icon: faWhatsapp,
      link: 'https://chat.whatsapp.com/B5PiHxXsDZeI5LfgZiJaFn',
      color: 'bg-green-500',
      hoverColor: 'hover:bg-green-600',
      description: 'Join our student community group',
    },
    {
      platform: 'WhatsApp Channel',
      icon: faCommentDots,
      link: 'https://whatsapp.com/channel/0029VaEWjjk3GJOv9piD000z',
      color: 'bg-green-500',
      hoverColor: 'hover:bg-green-600',
      description: 'Get instant updates and announcements',
    },
    {
      platform: 'YouTube',
      icon: faYoutube,
      link: 'https://www.youtube.com/c/KETAcademy',
      color: 'bg-red-600',
      hoverColor: 'hover:bg-red-700',
      description: 'Watch our educational content',
    },
    {
      platform: 'Facebook Page',
      icon: faFacebook,
      link: 'https://www.facebook.com/KET-AcademyOrientation-107170228312584/',
      color: 'bg-blue-600',
      hoverColor: 'hover:bg-blue-700',
      description: 'Follow our official updates',
    },
    {
      platform: 'Facebook Group',
      icon: faFacebook,
      link: 'https://www.facebook.com/groups/2008168369341398/',
      color: 'bg-blue-600',
      hoverColor: 'hover:bg-blue-700',
      description: 'Connect with fellow students',
    },
    {
      platform: 'LinkedIn',
      icon: faLinkedin,
      link: 'https://www.linkedin.com/company/77005269',
      color: 'bg-[#0077b5]',
      hoverColor: 'hover:bg-[#0077b5]/90',
      description: 'Professional network and updates',
    },
    {
      platform: 'Instagram',
      icon: faInstagram,
      link: 'https://www.instagram.com/ket.foundation/',
      color: 'bg-gradient-to-r from-purple-500 via-pink-500 to-orange-500',
      hoverColor: 'hover:opacity-90',
      description: 'See our visual content and stories',
    },
  ]

  const scrollToBottom = () => {
    messagesEndRef.current?.scrollIntoView({ behavior: 'smooth' })
  }

  useEffect(() => {
    scrollToBottom()
  }, [messages])

  const handleSendMessage = async (e) => {
    e.preventDefault()
    if (!inputMessage.trim() || !selectedAgent) return

    const newMessage = {
      content: inputMessage,
      sender: 'user',
      timestamp: new Date().toISOString(),
    }

    setMessages((prev) => [...prev, newMessage])
    setInputMessage('')
    setIsLoading(true)

    // Simulate AI response
    setTimeout(() => {
      const aiResponse = {
        content: `I'm your ${selectedAgent.title}. How can I help you today?`,
        sender: 'ai',
        timestamp: new Date().toISOString(),
      }
      setMessages((prev) => [...prev, aiResponse])
      setIsLoading(false)
    }, 1000)
  }

  const renderMessage = (message) => {
    return (
      <div
        className={`flex ${
          message.sender === 'user' ? 'justify-end' : 'justify-start'
        } mb-4`}
      >
        <div
          className={`max-w-[70%] rounded-lg p-4 ${
            message.sender === 'user'
              ? 'bg-primary text-white'
              : 'bg-white border border-gray-200'
          }`}
        >
          <ReactMarkdown
            remarkPlugins={[remarkGfm]}
            rehypePlugins={[rehypeRaw]}
            className={`prose prose-sm max-w-none ${
              message.sender === 'user'
                ? 'prose-invert'
                : 'prose-headings:text-gray-800 prose-p:text-gray-700'
            }`}
          >
            {message.content}
          </ReactMarkdown>
        </div>
      </div>
    )
  }

  const renderSection = (title, description, items, renderItem) => (
    <div className="mb-16">
      <div className="text-center mb-8">
        <h2 className="text-3xl font-bold text-gray-800 mb-4">{title}</h2>
        <p className="text-gray-600 max-w-2xl mx-auto text-lg">{description}</p>
      </div>
      <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-4 gap-8">
        {items.map(renderItem)}
      </div>
    </div>
  )

  const renderCommunity = (community, index) => (
    <div
      key={index}
      className="bg-white rounded-xl p-6 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 border border-gray-100"
    >
      <div className="mb-4">
        <h3 className="text-lg font-bold text-gray-800 mb-2">
          {community.name}
        </h3>
        <p className="text-sm text-gray-600 mb-3">{community.description}</p>
        <div className="flex items-center text-sm text-gray-500">
          <FontAwesomeIcon icon={faWhatsapp} className="mr-2 text-green-500" />
          <span>{community.members} members</span>
        </div>
      </div>
      <a
        href={community.link}
        target="_blank"
        rel="noopener noreferrer"
        className="inline-flex items-center justify-center w-full px-4 py-2 bg-green-500 text-white rounded-lg hover:bg-green-600 transition-colors"
      >
        <FontAwesomeIcon icon={faWhatsapp} className="mr-2" />
        Join Group
      </a>
    </div>
  )

  const renderSocialLink = (social, index) => (
    <div
      key={index}
      className="bg-white rounded-xl p-6 hover:shadow-2xl transition-all duration-300 transform hover:-translate-y-1 border border-gray-100"
    >
      <div className="flex items-center mb-4">
        <div className={`${social.color} p-4 rounded-xl mr-4`}>
          <FontAwesomeIcon icon={social.icon} className="text-white text-xl" />
        </div>
        <div>
          <h3 className="text-lg font-bold text-gray-800">{social.platform}</h3>
          <p className="text-sm text-gray-600">{social.description}</p>
        </div>
      </div>
      <a
        href={social.link}
        target="_blank"
        rel="noopener noreferrer"
        className={`inline-flex items-center justify-center w-full px-4 py-2 ${social.color} ${social.hoverColor} text-white rounded-lg transition-colors`}
      >
        <FontAwesomeIcon icon={social.icon} className="mr-2" />
        Connect
      </a>
    </div>
  )

  return (
    <main className="flex-1 bg-gray-50 min-h-screen">
      <BreadCrumb
        title="Chat Center"
        slugs={[
          { display: 'Dashboard', to: '/dashboard/home', active: false },
          { display: 'Chats', to: '/dashboard/chats', active: true },
        ]}
      />

      <div className="container mx-auto px-4 py-16">
        {!selectedAgent ? (
          <>
            {/* AI Agents Section */}
            {renderSection(
              'Your AI Learning Companions',
              'Our AI assistants are designed to provide personalized support across various educational needs. Choose your perfect learning partner and start your enhanced learning journey today.',
              aiAgents,
              (agent, index) => (
                <AIAgent
                  key={index}
                  {...agent}
                  onSelect={() => setSelectedAgent(agent)}
                />
              )
            )}

            {/* Communities Section */}
            {renderSection(
              'Join Student Communities',
              'Connect with fellow students, share knowledge, and support each other in these subject-specific study groups.',
              communities,
              renderCommunity
            )}

            {/* Social Media Links */}
            {renderSection(
              'Connect on Social Media',
              'Stay updated and connected with our community across various social platforms.',
              socialLinks,
              renderSocialLink
            )}
          </>
        ) : (
          <div className="bg-white rounded-2xl shadow-lg h-[calc(100vh-200px)] flex flex-col max-w-5xl mx-auto">
            <div className="p-6 border-b flex items-center bg-gray-50 rounded-t-2xl">
              <button
                onClick={() => setSelectedAgent(null)}
                className="mr-4 hover:bg-gray-100 p-3 rounded-xl transition-colors"
              >
                <FontAwesomeIcon icon={faArrowLeft} className="text-gray-600" />
              </button>
              <div className={`${selectedAgent.color} p-4 rounded-xl mr-4`}>
                <FontAwesomeIcon
                  icon={selectedAgent.icon}
                  className="text-white text-xl"
                />
              </div>
              <div>
                <h2 className="text-xl font-bold text-gray-800">
                  {selectedAgent.title}
                </h2>
                <p className="text-sm text-gray-600">AI Learning Assistant</p>
              </div>
            </div>

            <div className="flex-1 overflow-y-auto p-6 bg-gray-50">
              {messages.map((message, index) => (
                <div key={index}>{renderMessage(message)}</div>
              ))}
              {isLoading && (
                <div className="flex justify-start mb-4">
                  <div className="bg-white border border-gray-200 rounded-xl p-4">
                    <FontAwesomeIcon
                      icon={faSpinner}
                      spin
                      className="text-primary"
                    />
                  </div>
                </div>
              )}
              <div ref={messagesEndRef} />
            </div>

            <form
              onSubmit={handleSendMessage}
              className="p-6 border-t bg-white rounded-b-2xl"
            >
              <div className="flex items-center gap-4">
                <input
                  type="text"
                  value={inputMessage}
                  onChange={(e) => setInputMessage(e.target.value)}
                  placeholder={`Ask your ${selectedAgent.title.toLowerCase()} anything...`}
                  className="flex-1 border border-gray-200 rounded-xl px-6 py-3 focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent"
                />
                <button
                  type="submit"
                  disabled={!inputMessage.trim()}
                  className="bg-primary text-white px-8 py-3 rounded-xl hover:bg-primary-dark transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
                >
                  <FontAwesomeIcon icon={faPaperPlane} />
                </button>
              </div>
              <p className="text-xs text-gray-500 mt-3 text-center">
                K-AI is still in development and might make mistakes. We're
                continuously improving it.
              </p>
            </form>
          </div>
        )}
      </div>
    </main>
  )
}

export default Chats
