// Import FontAwesome icons for UI elements
import {
  faCamera,
  faExclamationTriangle,
  faSync,
  faTrashAlt,
  faUserEdit,
  faUserLock,
  faUserXmark,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'

// Import React hooks and utilities
import React, { useEffect, useState } from 'react'
import { concentrations, DUMMY_PROFILE_IMAGE } from '../../../utils/constants'
import BreadCrumb from '../../BreadCrumb'
import { useLocation, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import { useAuth } from '../../../utils/firebase/auth'
import {
  collection,
  query,
  getDocs,
  updateDoc,
  where,
  addDoc,
  deleteDoc,
} from 'firebase/firestore'
import {
  deleteUser,
  signOut,
  EmailAuthProvider,
  reauthenticateWithCredential,
  updatePassword,
} from 'firebase/auth'
import { firestore, auth } from '../../../utils/firebase'
//import './styles.scss'

// Import dummy profile image
//import dummy from '../../../assets/images/people/Kinlo.jpg'

// Define Cameroon regions for the region selector dropdown
const CAMEROON_REGIONS = [
  'Adamawa',
  'Centre',
  'East',
  'Far North',
  'Littoral',
  'North',
  'Northwest',
  'South',
  'Southwest',
  'West',
]

// Add this CSS class for required field indicator
const requiredField = <span className="text-error ml-1">*</span>

// Reusable UI component for form fields with labels and error handling
const FormField = ({ label, error, required, children }) => (
  <div className="mb-6">
    <label className="block text-sm font-medium text-gray-700 mb-2">
      {label}
      {required && requiredField}
      {error && (
        <span className="text-error text-sm ml-2 font-normal">Required</span>
      )}
    </label>
    <div className="mt-1">{children}</div>
  </div>
)

/**
 * Profile Component
 * Main component for managing user profile information including:
 * - Personal details
 * - Academic information
 * - Account settings
 */
const Profile = function () {
  // State management for user data and form fields
  const { authUser } = useAuth()
  const navigate = useNavigate()
  const [user, setUser] = useState(null) // Main user object
  //const [bio, setBio] = useState('') // User biography
  const [region, setRegion] = useState('') // User's region
  const [city, setCity] = useState('') // User's city
  const [edSystem, setEdSystem] = useState('') // Educational system (French/English)
  const [specialty, setSpecialty] = useState('') // Academic specialty
  const [level, setLevel] = useState('') // Academic level
  const [school, setSchool] = useState('') // User's school
  const [profile, setProfile] = useState({
    fullName: '',
    email: '',
    bio: '',
    profileImage: null,
    // ... other profile fields
  })

  // Add this state for tracking field errors
  const [fieldErrors, setFieldErrors] = useState({
    fullName: false,
    region: false,
    city: false,
    edSystem: false,
    specialty: false,
    level: false,
    school: false,
    bio: false,
  })

  const location = useLocation()
  const queryParams = new URLSearchParams(location.search)
  const isNewUser = queryParams.get('newUser') === 'true'

  const [isProfileComplete, setIsProfileComplete] = useState(false)

  // Add new state variables for password
  const [showPassword, setShowPassword] = useState(false)
  const [currentPassword, setCurrentPassword] = useState('')
  const [newPassword, setNewPassword] = useState('')

  // Effect hook to display welcome message for new users
  useEffect(() => {
    if (isNewUser && authUser) {
      toast.success(
        `Welcome to KET Academy, ${
          authUser.displayName || 'new student'
        }! Let's complete your profile to get started.`,
        {
          position: 'top-center',
          autoClose: 5000,
          hideProgressBar: false,
          closeOnClick: true,
          pauseOnHover: true,
          draggable: true,
          progress: undefined,
        }
      )
    }
  }, [isNewUser, authUser])

  // Effect hook to initialize user data from auth state
  useEffect(() => {
    if (authUser) {
      setUser({
        fullName: authUser.displayName || '',
        email: authUser.email || '',
        // ... other user fields
      })

      setProfile((prev) => ({
        ...prev,
        fullName: authUser.displayName || '',
        email: authUser.email || '',
      }))
    }
  }, [authUser])

  // Effect hook to fetch and populate profile data from Firestore
  useEffect(() => {
    const fetchProfileData = async () => {
      if (!authUser) return

      try {
        const userProfileRef = collection(firestore, 'userProfiles')
        const q = query(userProfileRef, where('uid', '==', authUser.uid))
        const querySnapshot = await getDocs(q)

        if (!querySnapshot.empty) {
          const profileData = querySnapshot.docs[0].data()

          // Set profile completion status
          setIsProfileComplete(profileData.profileCompleted || false)

          // Update all state values with existing data
          setProfile((prev) => ({
            ...prev,
            fullName: profileData.fullName || '',
            email: profileData.email || '',
            bio: profileData.bio || '',
            profileImage: profileData.profileImage || null,
          }))

          setRegion(profileData.region || '')
          setCity(profileData.city || '')
          setEdSystem(profileData.edSystem || '')
          setSpecialty(profileData.specialty || '')
          setLevel(profileData.level || '')
          setSchool(profileData.school || '')
        }
      } catch (error) {
        console.error('Error fetching profile:', error)
        toast.error('Error loading profile data')
      }
    }

    fetchProfileData()
  }, [authUser])

  // Handler functions for user interactions
  const handleImageUpload = async (e) => {
    const file = e.target.files?.[0]
    if (!file) return

    try {
      // Create a preview URL for immediate display
      const previewUrl = URL.createObjectURL(file)
      setProfile((prev) => ({ ...prev, profileImage: previewUrl }))
    } catch (error) {
      console.error('Error uploading image:', error)
      // Optionally show error message to user
    }
  }

  // Validation function to ensure all required fields are completed
  const checkProfileCompletion = (profileData) => {
    const requiredFields = {
      fullName: 'Full Name',
      region: 'Region',
      city: 'City',
      edSystem: 'Education System',
      specialty: 'Specialty',
      level: 'Academic Level',
      school: 'School Name',
      bio: 'Bio',
    }

    const errors = {}
    let isComplete = true

    Object.keys(requiredFields).forEach((field) => {
      const isEmpty = !profileData[field] || profileData[field].trim() === ''
      errors[field] = isEmpty
      if (isEmpty) isComplete = false
    })

    setFieldErrors(errors)

    if (!isComplete) {
      const missingFields = Object.entries(errors)
        .filter(([_, isError]) => isError)
        .map(([field, _]) => requiredFields[field])
        .join(', ')

      toast.error(`Please complete these mandatory fields: ${missingFields}`)
    }

    return isComplete
  }

  // Function to save profile changes to Firestore
  const handleSaveProfile = async () => {
    try {
      if (!authUser) {
        toast.error('You must be logged in to save your profile')
        return
      }

      // First, get the current user data
      const userProfileRef = collection(firestore, 'userProfiles')
      const q = query(userProfileRef, where('uid', '==', authUser.uid))
      const querySnapshot = await getDocs(q)

      // Prepare the profile data with all required fields
      const profileData = {
        uid: authUser.uid,
        fullName: user?.fullName || '',
        email: authUser.email || '', // Use authUser email to ensure consistency
        bio: profile.bio || '',
        region: region || '',
        city: city || '',
        edSystem: edSystem || '',
        specialty: specialty || '',
        level: level || '',
        school: school || '',
        profileImage: profile.profileImage || null,
        lastUpdated: new Date(),
        subjects: [], // Initialize empty subjects array
        profileCompleted: false, // Will be set to true after validation
      }

      // Validate all required fields
      const isComplete = checkProfileCompletion(profileData)
      if (!isComplete) {
        toast.error(
          'Please fill in all required fields to complete your profile'
        )
        return
      }

      // Set profile as completed if validation passes
      profileData.profileCompleted = true

      try {
        if (querySnapshot.empty) {
          // Create new profile document
          const docRef = await addDoc(userProfileRef, {
            ...profileData,
            createdAt: new Date(),
          })
          console.log('Created new profile with ID:', docRef.id)
        } else {
          // Update existing profile document
          const docRef = querySnapshot.docs[0].ref
          await updateDoc(docRef, profileData)
          console.log('Updated profile with ID:', docRef.id)
        }

        // Verify the save was successful
        const verifySnapshot = await getDocs(q)
        const savedData = verifySnapshot.docs[0].data()
        console.log('Saved profile data:', savedData)

        toast.success('Profile saved successfully!')

        if (isNewUser) {
          navigate('/dashboard/home', { replace: true })
        }

        setIsProfileComplete(true)
      } catch (error) {
        console.error('Firebase operation failed:', error)
        throw error // Re-throw to be caught by outer try-catch
      }
    } catch (error) {
      console.error('Error saving profile:', error)
      toast.error(`Error saving profile: ${error.message}`)
    }
  }

  // Function to handle account deletion
  const handleDeleteAccount = async () => {
    try {
      const result = await toast.promise(
        new Promise((resolve) => {
          toast.warn(
            <div>
              <p>Are you sure you want to delete your account?</p>
              <p className="text-sm mt-2">This action cannot be undone.</p>
              <div className="mt-4 flex justify-end space-x-2">
                <button
                  onClick={() => resolve(false)}
                  className="px-4 py-2 text-sm text-gray-600 hover:text-gray-800"
                >
                  Cancel
                </button>
                <button
                  onClick={() => resolve(true)}
                  className="px-4 py-2 text-sm bg-error text-white rounded hover:bg-error/80"
                >
                  Delete
                </button>
              </div>
            </div>,
            {
              autoClose: false,
              closeButton: false,
            }
          )
        }),
        {
          pending: 'Please confirm your choice',
          success: 'Confirmation received',
          error: 'Confirmation failed',
        }
      )

      if (!result) return

      if (!authUser) {
        toast.error('You must be logged in to delete your account')
        return
      }

      // Get the user profile document
      const userProfileRef = collection(firestore, 'userProfiles')
      const q = query(userProfileRef, where('uid', '==', authUser.uid))
      const querySnapshot = await getDocs(q)

      // Delete the user profile document
      if (!querySnapshot.empty) {
        const docRef = querySnapshot.docs[0].ref
        await deleteDoc(docRef)
      }

      // Delete the user account
      await deleteUser(authUser)
      toast.success('Your account has been successfully deleted')
      window.scrollTo(0, 0)
      navigate('/', { replace: true })
    } catch (error) {
      console.error('Error deleting account:', error)
      toast.error(
        error.message || 'Error deleting account. Please try again later.'
      )
    }
  }

  // Function to handle account disable/enable
  const handleDisableAccount = async () => {
    try {
      if (!authUser) {
        toast.error('You must be logged in to disable your account')
        return
      }

      // Get the user profile document
      const userProfileRef = collection(firestore, 'userProfiles')
      const q = query(userProfileRef, where('uid', '==', authUser.uid))
      const querySnapshot = await getDocs(q)

      if (querySnapshot.empty) {
        toast.error('User profile not found')
        return
      }

      const docRef = querySnapshot.docs[0].ref
      const profileData = querySnapshot.docs[0].data()
      const isCurrentlyDisabled = profileData.disabled || false

      // Update the disabled status
      await updateDoc(docRef, {
        disabled: !isCurrentlyDisabled,
        lastUpdated: new Date(),
      })

      toast.success(
        `Account ${isCurrentlyDisabled ? 'enabled' : 'disabled'} successfully`
      )

      if (!isCurrentlyDisabled) {
        // If we're disabling the account, sign out the user
        await signOut(auth)
        window.scrollTo(0, 0)
        navigate('/', { replace: true })
      }
    } catch (error) {
      console.error('Error toggling account status:', error)
      toast.error(
        error.message ||
          'Error updating account status. Please try again later.'
      )
    }
  }

  // Function to handle password change
  const handlePasswordChange = async () => {
    if (!currentPassword || !newPassword) {
      toast.error('Please fill in both password fields')
      return
    }

    if (newPassword.length < 8) {
      toast.error('New password must be at least 8 characters long')
      return
    }

    try {
      // Get credentials with current password
      const credential = EmailAuthProvider.credential(
        authUser.email,
        currentPassword
      )

      // Reauthenticate
      await reauthenticateWithCredential(authUser, credential)

      // Update password
      await updatePassword(authUser, newPassword)

      // Clear form
      setCurrentPassword('')
      setNewPassword('')
      setShowPassword(false)

      toast.success('Password updated successfully!')
    } catch (error) {
      console.error('Error updating password:', error)
      if (error.code === 'auth/wrong-password') {
        toast.error('Current password is incorrect')
      } else {
        toast.error('Error updating password. Please try again later.')
      }
    }
  }

  // Component render structure
  return (
    <main className="w-full min-h-screen bg-gray-50">
      <div className="w-full bg-gradient-to-r from-primary to-primary/80 text-white py-8 px-4 md:px-8">
        <div className="w-full">
          <BreadCrumb
            title="Profile"
            slugs={[
              { display: 'Dashboard', to: '/dashboard/home', active: false },
              { display: 'Profile', to: '/dashboard/profile', active: true },
            ]}
            lightMode={true}
          />

          <div className="mt-4 mb-8">
            {authUser && (
              <div className="text-center">
                <p className="text-white font-bold text-0.5xl">
                  Welcome to KET Academy, {authUser.displayName || 'Student'}!
                  {isNewUser
                    ? " Let's complete your profile to get started."
                    : " We're glad to have you back."}
                </p>
              </div>
            )}
          </div>

          <div className="mt-8">
            <h1 className="text-4xl font-bold mb-2">Profile Settings</h1>
            <p className="text-white/80">
              Manage your personal information and account preferences
            </p>
          </div>
        </div>
      </div>

      <section className="w-full p-8 md:p-12 bg-white">
        <div className="w-full">
          {/* Personal Profile Section */}
          <div className="w-full py-6">
            <div className="mb-1">
              <h2 className="text-2xl mb-0.1 text-primary/100">
                Your Personal Profile
              </h2>
              <p className="text-sm leading-6">
                Your profile contains your basic information
              </p>
            </div>

            <div className="flex flex-wrap gap-12 p-6 bg-secondary/[0.02] rounded-lg">
              {/* Profile Picture Management */}
              <div className="flex-none">
                <h4 className="font-primary text-base font-extrabold text-neutral-800 pb-1">
                  Profile Picture
                </h4>
                <div className="relative">
                  <img
                    src={profile.profileImage || DUMMY_PROFILE_IMAGE}
                    alt="Profile"
                    className="w-[220px] h-[220px] rounded-full object-cover border-4 border-white shadow-md"
                  />
                  {/* Camera Button - Top Right */}
                  <label className="absolute top-2 right-2 cursor-pointer p-3 bg-white rounded-full shadow-md transition-transform duration-300 hover:scale-110">
                    <input
                      type="file"
                      className="hidden"
                      accept="image/*"
                      onChange={handleImageUpload}
                    />
                    <FontAwesomeIcon
                      icon={faCamera}
                      className="text-primary hover:text-primary/80 text-lg"
                    />
                  </label>
                  {/* Delete Button - Bottom Right - Only show if there's a custom profile image */}
                  {profile.profileImage && (
                    <button
                      className="absolute bottom-2 right-2 p-3 bg-white rounded-full shadow-md transition-transform duration-300 hover:scale-110"
                      onClick={() =>
                        setProfile((prev) => ({ ...prev, profileImage: null }))
                      }
                    >
                      <FontAwesomeIcon
                        icon={faTrashAlt}
                        className="text-error hover:text-error/80 text-lg"
                      />
                    </button>
                  )}
                </div>
              </div>

              {/* Biography Section */}
              <div className="flex-1 w-full">
                <h4 className="font-primary text-base font-extrabold text-neutral-800 pb-2">
                  Personal Bio
                </h4>
                <textarea
                  className="w-full min-h-[180px] p-4 leading-6 resize-y rounded border border-primary focus:ring-2 focus:ring-primary/30"
                  value={profile.bio}
                  onChange={(e) =>
                    setProfile((prev) => ({ ...prev, bio: e.target.value }))
                  }
                  placeholder="Tell us about yourself..."
                />
              </div>
            </div>

            {/* Profile Update Button */}
            <button
              onClick={handleSaveProfile}
              className="mt-4 px-6 py-3 bg-primary text-white rounded-lg font-medium tracking-wide transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-primary/30"
            >
              <FontAwesomeIcon icon={faUserEdit} className="mr-2" />
              Update Profile
            </button>
          </div>

          {/* Student Profile Section */}
          <div className="w-full py-6">
            <div className="mb-8">
              <h2 className="text-2xl mb-1 py-0 text-primary/90">
                Your KET Academy <strong>Student</strong> Profile
              </h2>
              <p className="text-sm leading-6">
                Your personal student information for a personalised experience.
              </p>
            </div>

            <div className="space-y-8">
              {/* KET ID Card Section */}
              <div>
                <h4 className="font-primary text-base font-extrabold text-neutral-800 py-4">
                  Your KET ID card
                </h4>
                {!isProfileComplete && (
                  <div
                    className="bg-orange-100 border-l-4 border-orange-500 text-orange-700 p-4 my-4"
                    role="alert"
                  >
                    <p className="font-bold">Incomplete profile</p>
                    <p>
                      Your ID Card will be created after you verify your email
                      and complete setting up this profile page
                    </p>
                  </div>
                )}
                {isProfileComplete && (
                  <div className="bg-green-100 border-l-4 border-green-500 text-green-700 p-4 my-4">
                    <p className="font-bold">Profile Complete!</p>
                    <p>Your KET ID card is now available.</p>
                  </div>
                )}
              </div>

              {/* Form Fields */}
              <div className="grid grid-cols-1 md:grid-cols-2 lg:grid-cols-3 gap-6">
                {/* Name Fields */}
                <FormField
                  label="Full Name"
                  error={fieldErrors.fullName}
                  required
                >
                  <input
                    type="text"
                    className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary/20 ${
                      fieldErrors.fullName
                        ? 'border-error/50 bg-error/5'
                        : 'border-gray-300'
                    }`}
                    value={user?.fullName || ''}
                    onChange={(e) => {
                      setUser({ ...user, fullName: e.target.value })
                      setFieldErrors((prev) => ({ ...prev, fullName: false }))
                    }}
                    placeholder="Enter your full name"
                  />
                </FormField>

                {/* Region Field */}
                <FormField label="Region" error={fieldErrors.region} required>
                  <select
                    className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary/20 ${
                      fieldErrors.region
                        ? 'border-error/50 bg-error/5'
                        : 'border-gray-300'
                    }`}
                    value={region}
                    onChange={(e) => {
                      setRegion(e.target.value)
                      setFieldErrors((prev) => ({ ...prev, region: false }))
                    }}
                    required
                  >
                    <option value="">Select Region</option>
                    {CAMEROON_REGIONS.map((regionName) => (
                      <option key={regionName} value={regionName}>
                        {regionName}
                      </option>
                    ))}
                  </select>
                </FormField>

                {/* City Field */}
                <FormField
                  label="City of studies"
                  error={fieldErrors.city}
                  required
                >
                  <input
                    type="text"
                    className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary/20 ${
                      fieldErrors.city
                        ? 'border-error/50 bg-error/5'
                        : 'border-gray-300'
                    }`}
                    value={city}
                    onChange={(e) => {
                      setCity(e.target.value)
                      setFieldErrors((prev) => ({ ...prev, city: false }))
                    }}
                    placeholder="Enter your city"
                    required
                  />
                </FormField>

                {/* Education System Field */}
                <FormField label="Education System">
                  <select
                    className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary/20 ${
                      fieldErrors.edSystem
                        ? 'border-error/50 bg-error/5'
                        : 'border-gray-300'
                    }`}
                    value={edSystem}
                    onChange={(e) => setEdSystem(e.target.value)}
                  >
                    <option value="">Select System</option>
                    <option value="french">French</option>
                    <option value="english">English</option>
                  </select>
                </FormField>

                {/* Specialty Field */}
                <FormField label="Specialty">
                  <select
                    className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary/20 ${
                      fieldErrors.specialty
                        ? 'border-error/50 bg-error/5'
                        : 'border-gray-300'
                    }`}
                    value={specialty}
                    onChange={(e) => setSpecialty(e.target.value)}
                  >
                    <option value="">Select Specialty</option>
                    {concentrations.map((concentration, index) => (
                      <option key={index} value={concentration.toLowerCase()}>
                        {concentration}
                      </option>
                    ))}
                  </select>
                </FormField>

                {/* Academic Level Field */}
                <FormField label="Academic Level">
                  <select
                    className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary/20 ${
                      fieldErrors.level
                        ? 'border-error/50 bg-error/5'
                        : 'border-gray-300'
                    }`}
                    value={level}
                    onChange={(e) => setLevel(e.target.value)}
                  >
                    <option value="">Select Level</option>
                    <option value="form1">Form 1</option>
                    <option value="form2">Form 2</option>
                    <option value="form3">Form 3</option>
                    <option value="form4">Form 4</option>
                    <option value="form5">Form 5</option>
                    <option value="lower6">Lower Sixths</option>
                    <option value="upper6">Upper Sixths</option>
                  </select>
                </FormField>

                {/* School Field */}
                <FormField label="School">
                  <input
                    type="text"
                    className={`w-full p-3 border rounded-lg focus:ring-2 focus:ring-primary/20 ${
                      fieldErrors.school
                        ? 'border-error/50 bg-error/5'
                        : 'border-gray-300'
                    }`}
                    value={school}
                    onChange={(e) => setSchool(e.target.value)}
                    placeholder="Enter your school name"
                  />
                </FormField>
              </div>

              {/* Save Changes Button */}
              <button
                onClick={handleSaveProfile}
                className="mt-8 px-6 py-2 bg-primary text-white rounded-md transition-all duration-300 hover:-translate-y-1 hover:shadow-lg"
              >
                <FontAwesomeIcon icon={faSync} className="mr-2" />
                Save Changes
              </button>
            </div>
          </div>

          {/* Account Settings Section */}
          <div className="w-full py-8 border-t border-gray-200 mt-8">
            <div className="mb-8">
              <h2 className="text-2xl mb-2 text-primary/90 flex items-center gap-2">
                <span>Account Settings</span>
                <span className="text-base font-normal bg-primary/10 text-primary px-3 py-1 rounded-full">
                  Personal Account
                </span>
              </h2>
              <p className="text-sm text-gray-600">
                Manage your account security and preferences
              </p>
            </div>

            <div className="grid grid-cols-1 md:grid-cols-2 gap-8">
              {/* Email Section */}
              <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm">
                <h4 className="font-primary text-lg font-bold text-neutral-800 mb-4">
                  Email Address
                </h4>
                <p className="text-sm text-gray-600 mb-4">
                  Change your email here. Note that your previous email will no
                  longer be associated with this account.
                </p>
                <div className="space-y-4">
                  <input
                    type="email"
                    className="w-full p-3 border border-primary/30 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                    value={user?.email || ''}
                    onChange={(e) =>
                      setUser({ ...user, email: e.target.value })
                    }
                    placeholder="Enter new email address"
                  />
                  <button className="w-full md:w-auto px-6 py-3 bg-accent text-white rounded-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-accent/20">
                    Update Email
                  </button>
                </div>
              </div>

              {/* Password Section */}
              <div className="bg-white p-6 rounded-xl border border-gray-100 shadow-sm">
                <h4 className="font-primary text-lg font-bold text-neutral-800 mb-4">
                  Password
                </h4>
                <p className="text-sm text-gray-600 mb-4">
                  Update your password. You'll be logged out from all other
                  devices.
                </p>
                <div className="space-y-4">
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="w-full p-3 border border-primary/30 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                    placeholder="Current password"
                    value={currentPassword}
                    onChange={(e) => setCurrentPassword(e.target.value)}
                  />
                  <input
                    type={showPassword ? 'text' : 'password'}
                    className="w-full p-3 border border-primary/30 rounded-lg focus:ring-2 focus:ring-primary/20 focus:border-primary"
                    placeholder="New password"
                    value={newPassword}
                    onChange={(e) => setNewPassword(e.target.value)}
                  />
                  <div className="flex items-center gap-2 mb-4">
                    <input
                      type="checkbox"
                      id="showPassword"
                      checked={showPassword}
                      onChange={() => setShowPassword(!showPassword)}
                      className="rounded border-gray-300"
                    />
                    <label
                      htmlFor="showPassword"
                      className="text-sm text-gray-600"
                    >
                      Show password
                    </label>
                  </div>
                  <button
                    onClick={handlePasswordChange}
                    className="w-full md:w-auto px-6 py-3 bg-accent text-white rounded-lg transition-all duration-300 hover:-translate-y-1 hover:shadow-lg hover:shadow-accent/20"
                  >
                    <FontAwesomeIcon icon={faSync} className="mr-2" />
                    Change Password
                  </button>
                </div>
              </div>

              {/* Account Management Section */}
              <div className="md:col-span-2 bg-white p-6 rounded-xl border border-gray-100 shadow-sm">
                <h4 className="font-primary text-lg font-bold text-neutral-800 mb-4 flex items-center">
                  Account Management
                  <FontAwesomeIcon
                    icon={faExclamationTriangle}
                    className="ml-2 text-error/80"
                  />
                </h4>

                <div className="grid grid-cols-1 md:grid-cols-2 gap-6">
                  <div className="p-4 bg-error/5 rounded-lg">
                    <h5 className="font-semibold mb-2 text-error">
                      Delete Account
                    </h5>
                    <p className="text-sm text-gray-600 mb-4">
                      This action is permanent. All your data will be
                      permanently deleted.
                    </p>
                    <button
                      onClick={handleDeleteAccount}
                      className="w-full px-6 py-3 bg-white border-2 border-error text-error rounded-lg hover:bg-error hover:text-white transition-colors duration-300"
                    >
                      <FontAwesomeIcon icon={faUserXmark} className="mr-2" />
                      Delete My Account
                    </button>
                  </div>

                  <div className="p-4 bg-info/5 rounded-lg">
                    <h5 className="font-semibold mb-2 text-info">
                      Disable Account
                    </h5>
                    <p className="text-sm text-gray-600 mb-4">
                      Temporarily disable your account. You can reactivate it
                      anytime.
                    </p>
                    <button
                      onClick={handleDisableAccount}
                      className="w-full px-6 py-3 bg-white border-2 border-info text-info rounded-lg hover:bg-info hover:text-white transition-colors duration-300"
                    >
                      <FontAwesomeIcon icon={faUserLock} className="mr-2" />
                      {user?.disabled
                        ? 'Enable My Account'
                        : 'Disable My Account'}
                    </button>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </section>
    </main>
  )
}

export default Profile

export const ProtectedRoute = ({ children }) => {
  const { authUser, isLoading } = useAuth()
  const navigate = useNavigate()
  const [isProfileComplete, setIsProfileComplete] = useState(false)
  const [isChecking, setIsChecking] = useState(true)

  useEffect(() => {
    const checkProfileStatus = async () => {
      if (!authUser) {
        navigate('/account/login')
        return
      }

      try {
        const userProfileRef = collection(firestore, 'userProfiles')
        const q = query(userProfileRef, where('uid', '==', authUser.uid))
        const querySnapshot = await getDocs(q)

        if (
          querySnapshot.empty ||
          !querySnapshot.docs[0].data().profileCompleted
        ) {
          toast.warning('Please complete your profile first')
          navigate('/dashboard/profile?newUser=true')
          return
        }

        setIsProfileComplete(true)
      } catch (error) {
        console.error('Error checking profile status:', error)
      } finally {
        setIsChecking(false)
      }
    }

    if (!isLoading) {
      checkProfileStatus()
    }
  }, [authUser, isLoading, navigate])

  if (isLoading || isChecking) {
    return <div>Loading...</div>
  }

  return isProfileComplete ? children : null
}
