import { logEvent } from 'firebase/analytics'
import { analytics } from './firebase'

// Analytics event tracking functions
export const trackPageView = (pageName) => {
  logEvent(analytics, 'page_view', {
    page_name: pageName,
  })
}

export const trackResourceView = (resourceId, resourceTitle, resourceType) => {
  logEvent(analytics, 'resource_view', {
    resource_id: resourceId,
    resource_title: resourceTitle,
    resource_type: resourceType,
  })
}

export const trackResourceSearch = (searchQuery) => {
  logEvent(analytics, 'resource_search', {
    search_term: searchQuery,
  })
}

export const trackResourceFilter = (filterType, filterValue) => {
  logEvent(analytics, 'resource_filter', {
    filter_type: filterType,
    filter_value: filterValue,
  })
}

export const trackResourceDownload = (resourceId, resourceTitle) => {
  logEvent(analytics, 'resource_download', {
    resource_id: resourceId,
    resource_title: resourceTitle,
  })
}

export const trackTabChange = (tabName) => {
  logEvent(analytics, 'tab_change', {
    tab_name: tabName,
  })
}
