import {
  GoogleAuthProvider,
  createUserWithEmailAndPassword,
  signInWithPopup,
  updateProfile,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { auth } from '../../../utils/firebase'

import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'animate.css'
import { toast } from 'react-toastify'
import Illustration from '../../../assets/images/illustrations/Boy-attending-video-lecture.png'
import googleLogo from '../../../assets/images/web/google-logo.png'
import mailLogo from '../../../assets/images/web/mail.png'
import oneTimePassword from '../../../assets/images/web/one-time-password.png'
import { Navbar } from '../../../components'
import { useAuth } from '../../../utils/firebase/auth'
import './style.scss'
import { addDoc, collection, query, where, getDocs } from 'firebase/firestore'
import { firestore } from '../../../utils/firebase'

const SignUpPage = () => {
  const { authUser, isLoading } = useAuth()
  const [signUpType, setSignUpType] = useState(null)
  const navigate = useNavigate()
  const [formData, setFormData] = useState({
    // Basic auth info
    name: '',
    email: '',
    phone: '',
    password: '',

    // Profile info
    bio: '',
    region: '',
    city: '',
    edSystem: '',
    specialty: '',
    level: '',
    school: '',
    subjects: [],
    profileImage: null,
  })

  const signUpwithGoogle = async (event) => {
    event.stopPropagation()
    try {
      const { user } = await signInWithPopup(auth, new GoogleAuthProvider())

      // Check if user profile exists
      const userProfilesRef = collection(firestore, 'userProfiles')
      const q = query(userProfilesRef, where('uid', '==', user.uid))
      const querySnapshot = await getDocs(q)

      if (querySnapshot.empty) {
        // Create initial profile with required fields
        const userProfileData = {
          uid: user.uid,
          fullName: user.displayName || '',
          email: user.email,
          bio: '',
          region: '',
          city: '',
          edSystem: '',
          level: '',
          school: '',
          subjects: [],
          profileImage: null,
          profileCompleted: false,
          emailVerified: user.emailVerified,
          requiredFields: {
            fullName: false,
            region: false,
            city: false,
            edSystem: false,
            level: false,
            school: false,
          },
          createdAt: new Date(),
          lastUpdated: new Date(),
        }
        await addDoc(collection(firestore, 'userProfiles'), userProfileData)
        navigate('/dashboard/profile?newUser=true', { replace: true })
      }
    } catch (error) {
      toast.error(error.message)
    }
  }

  useEffect(() => {
    if (isLoading || !authUser) return
    toast.info(
      `You are already signed in as ${
        authUser?.displayName || authUser?.email
      } .`,
      { autoClose: 5000 }
    )
    navigate('/dashboard/profile', { replace: true })
  })

  return (
    <div className="signup__home">
      <Navbar />
      <div className="left">
        {/* <Link to="/">
          <div className="logo">
            <img src={logo} alt="KET Academy Logo" />
          </div>
        </Link> */}

        {signUpType && (
          <SignUpContainer
            signUpType={signUpType}
            setSignUpType={setSignUpType}
            formData={formData}
            setFormData={setFormData}
          />
        )}
        {!signUpType && (
          <div className="signup__options">
            <h2 className="text-3xl font-bold text-primary my-4 mb-8 text-center">
              Sign Up <span className="text-sm font-semibold">with</span>
            </h2>
            <button onClick={() => setSignUpType('EMAIL_ONLY')}>
              <img src={mailLogo} alt="mail"></img>
              Email Only
            </button>
            <button onClick={() => setSignUpType('EMAIL_PASSWORD')}>
              <img src={oneTimePassword} alt="pwd"></img>
              Email and Password
            </button>
            <button onClick={signUpwithGoogle}>
              <img src={googleLogo} alt="Google"></img>
              Google
            </button>
            {/* <button onClick={signUpwithApple}>
              <img src={appleLogo} alt="Apple"></img>
              Apple
            </button> */}
            {/* <button onClick={() => setSignUpType('PHONE')}>
              <img src={phoneLogo} alt="Phone"></img>
              Phone Number
            </button> */}
          </div>
        )}
        <div className="to_signup mt-5">
          <span>
            Already have an account? <Link to={'/account/login'}>Login</Link>
          </span>
        </div>
      </div>

      <div className="right">
        <h1 className="text-3xl font-bold text-center">
          Welcome to KET Academy
        </h1>

        <div className="illustration">
          <img src={Illustration} alt="Illustration" />
          <div></div>
        </div>
        <h3 className="text-3xl text-center font-semibold">
          Evolving Education with <br />
          <span className="text-accent"> DIGITAL LEVERAGE</span>
        </h3>
      </div>
    </div>
  )
}

const SignUpContainer = ({
  signUpType,
  setSignUpType,
  formData,
  setFormData,
}) => {
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)
  const navigate = useNavigate()

  const handleInputChange = (field, value) => {
    setFormData((prev) => ({
      ...prev,
      [field]: value,
    }))
  }

  const handleSubmit = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsSubmitting(true)

    try {
      if (!formData.email || !formData.name || !formData.password) {
        throw new Error('Name, email and password are required')
      }

      if (formData.password.length < 8) {
        throw new Error('Password must be at least 8 characters long')
      }

      const userCredential = await createUserWithEmailAndPassword(
        auth,
        formData.email,
        formData.password
      )

      // Update display name
      await updateProfile(userCredential.user, {
        displayName: formData.name,
      })

      // Create initial profile
      const userProfileData = {
        uid: userCredential.user.uid,
        fullName: formData.name,
        email: formData.email,
        bio: '',
        region: '',
        city: '',
        edSystem: '',
        level: '',
        school: '',
        subjects: [],
        profileCompleted: false,
        createdAt: new Date(),
        lastUpdated: new Date(),
      }

      await addDoc(collection(firestore, 'userProfiles'), userProfileData)
      toast.success('Account created successfully!')
      navigate('/dashboard/profile?newUser=true', { replace: true })
    } catch (error) {
      console.error('Signup error:', error)
      toast.error(error.message || 'Error creating account')
    } finally {
      setIsSubmitting(false)
    }
  }

  return (
    <div className="form__container">
      <span className="back__button" onClick={() => setSignUpType(null)}>
        <FontAwesomeIcon icon={faArrowLeft} />
      </span>

      <div className="title__text">
        <h2>Sign Up</h2>
      </div>

      <form onSubmit={handleSubmit}>
        <div className="field">
          <label>Your Full Name</label>
          <input
            type="text"
            value={formData.name}
            onChange={(e) => handleInputChange('name', e.target.value)}
            placeholder="Ex: John Doe"
            minLength={2}
            maxLength={30}
            required
          />
        </div>

        <div className="field">
          <label>Your Email</label>
          <input
            type="email"
            value={formData.email}
            onChange={(e) => handleInputChange('email', e.target.value)}
            placeholder="Ex: john.doe@example.com"
            required
          />
        </div>

        <div className="field">
          <label>Your Password</label>
          <input
            className="su-password"
            type={showPassword ? 'text' : 'password'}
            value={formData.password}
            onChange={(e) => handleInputChange('password', e.target.value)}
            placeholder="Enter a strong password"
            minLength={8}
            maxLength={20}
            required
          />
          <FontAwesomeIcon
            className="show-pass"
            onClick={() => setShowPassword(!showPassword)}
            icon={showPassword ? faEyeSlash : faEye}
          />
        </div>

        <div className="field">
          <button
            type="submit"
            className="w-full px-6 py-3 bg-primary text-white rounded-lg"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Creating Account...' : 'Create Account'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default SignUpPage
