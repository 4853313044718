import {
  faBars,
  faBell,
  faComment,
  faSearch,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import React, { useEffect, useState } from 'react'
import { BoxArrowRight } from 'react-bootstrap-icons'
import { Link, useNavigate } from 'react-router-dom'
import { Alert } from '..'
import logoThin from '../../assets/images/academy/logo-tall.png'
import logo from '../../assets/images/academy/logo.png'
import { auth } from '../../utils/firebase'
import { useAuth } from '../../utils/firebase/auth'
import './styles.scss'

const DashboardHeader = function ({ onNavToggle }) {
  const navigate = useNavigate()

  const [headerLogo, setHeaderLogo] = useState(logo)
  const { authUser } = useAuth()

  // Dynamically set the logo based on window width
  const setLogo = (_) =>
    setHeaderLogo(window.innerWidth < 600 ? logoThin : logo)

  // Handle sign out process with confirmation
  const onSignOut = async (_) => {
    const isSignOut = await Alert.fire({
      icon: 'question',
      title: 'Sign Out!',
      text: `Do you want to sign out now?`,
      showCancelButton: true,
      confirmButtonText: 'Yes, Log Me Out.',
      cancelButtonText: 'No',
    })

    if (!isSignOut?.isConfirmed) return

    await auth.signOut()
    navigate('/', { replace: true })
    // toast.success('You have been logged out.')
  }

  // Setup and cleanup for responsive logo handling
  useEffect(() => {
    setLogo()
    window.addEventListener('resize', setLogo)
    return () => {
      window.removeEventListener('resize', setLogo)
    }
  }, [authUser])

  return (
    <nav className="dashboard__nav">
      <div className="logo__container">
        <Link to="/">
          <img src={headerLogo} alt="KET Academy Logo" />
        </Link>
        <div className="toggle" onClick={onNavToggle}>
          <FontAwesomeIcon icon={faBars} />
        </div>
      </div>

      <div className="searchbar">
        <form method="POST" action="#">
          <input
            type="text"
            name="query"
            placeholder="Search"
            title="Enter search keyword"
          />
          <button type="submit" title="Search">
            <FontAwesomeIcon icon={faSearch} />
          </button>
        </form>
      </div>

      <div className="navigation">
        <ul>
          <li className="notification">
            <Link>
              <FontAwesomeIcon icon={faBell} />
              <span className="badge">0</span>
            </Link>
          </li>
          <li className="message">
            <Link>
              <FontAwesomeIcon icon={faComment} />
              <span className="badge">0</span>
            </Link>
          </li>
          <li className="profile inline-block">
            <img src={authUser?.photoURL} alt="Profile pic" />
            <span>{authUser?.displayName}</span>
            <button
              className="ml-4 text-accent text-xs"
              title="Sign out"
              onClick={onSignOut}
            >
              <BoxArrowRight className="text-xl font-black outline-2" />
              {/* <FontAwesomeIcon icon={faSignOutAlt} /> */}
            </button>
          </li>
        </ul>
      </div>
    </nav>
  )
}

export default DashboardHeader
