import React, { useState } from 'react'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { faPaperPlane, faTimes } from '@fortawesome/free-solid-svg-icons'
import { generateAIResponse } from '../../utils/openai'

const AIChat = ({ isOpen, onClose }) => {
  const [messages, setMessages] = useState([
    {
      type: 'bot',
      content: 'Hello! I am KAI. How can I help you today?',
    },
  ])
  const [input, setInput] = useState('')
  const [isLoading, setIsLoading] = useState(false)
  const [streamingMessage, setStreamingMessage] = useState('')

  const handleSubmit = async (e) => {
    e.preventDefault()
    if (!input.trim()) return

    const userMessage = { type: 'user', content: input }
    setMessages((prev) => [...prev, userMessage])
    setInput('')
    setIsLoading(true)

    try {
      let fullResponse = ''

      // Add an empty bot message that will be streamed
      setMessages((prev) => [...prev, { type: 'bot', content: '' }])

      await generateAIResponse(input, (chunk) => {
        fullResponse += chunk
        setStreamingMessage(fullResponse) // Use the accumulated response
      })

      // Update the last message with the complete response
      setMessages((prev) => {
        const newMessages = [...prev]
        newMessages[newMessages.length - 1].content = fullResponse
        return newMessages
      })
    } catch (error) {
      const errorMessage = error.message.includes('API key')
        ? 'There was an authentication error. Please try again later.'
        : 'I apologize, but I encountered an error.'

      setMessages((prev) => [
        ...prev,
        {
          type: 'bot',
          content: errorMessage,
          isError: true,
        },
      ])
    } finally {
      setIsLoading(false)
      setStreamingMessage('')
    }
  }

  if (!isOpen) return null

  return (
    <div className="fixed inset-0 md:inset-auto md:bottom-24 md:right-4 md:w-96 md:h-[500px] flex flex-col bg-white shadow-2xl z-50 transition-all duration-300 ease-in-out">
      {/* Header */}
      <div className="bg-primary px-4 py-3 flex justify-between items-center sticky top-0 z-10">
        <div className="flex items-center space-x-2">
          <div className="w-2 h-2 bg-accent rounded-full animate-pulse"></div>
          <h3 className="text-white font-semibold text-base md:text-lg">
            KET Academy AI Assistant
          </h3>
        </div>
        <button
          onClick={onClose}
          className="text-white hover:text-gray-200 transition-colors p-2 rounded-full hover:bg-primary-700"
          aria-label="Close chat"
        >
          <FontAwesomeIcon icon={faTimes} className="text-lg" />
        </button>
      </div>

      {/* Messages */}
      <div className="flex-1 p-4 overflow-y-auto space-y-4 bg-gray-50">
        {messages.map((message, index) => (
          <div
            key={index}
            className={`flex ${
              message.type === 'user' ? 'justify-end' : 'justify-start'
            } animate-fadeIn`}
          >
            <div
              className={`max-w-[85%] p-3 rounded-2xl text-sm md:text-base break-words ${
                message.type === 'user'
                  ? 'bg-primary text-white rounded-br-none'
                  : 'bg-white text-gray-800 shadow-sm rounded-bl-none'
              }`}
            >
              {index === messages.length - 1 && message.type === 'bot'
                ? streamingMessage || message.content
                : message.content}
            </div>
          </div>
        ))}
        {isLoading && (
          <div className="flex justify-start animate-fadeIn">
            <div className="max-w-[85%] p-3 rounded-2xl bg-white shadow-sm">
              <div className="flex space-x-2">
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-100"></div>
                <div className="w-2 h-2 bg-gray-400 rounded-full animate-bounce delay-200"></div>
              </div>
            </div>
          </div>
        )}
      </div>

      {/* Input */}
      <form onSubmit={handleSubmit} className="p-4 bg-white border-t shadow-lg">
        <div className="flex gap-2 items-center">
          <input
            type="text"
            value={input}
            onChange={(e) => setInput(e.target.value)}
            placeholder="Type your message..."
            className="flex-1 p-3 text-sm md:text-base border rounded-full focus:outline-none focus:ring-2 focus:ring-primary focus:border-transparent bg-gray-50 text-gray-900 placeholder:text-gray-500"
          />
          <button
            type="submit"
            className="bg-primary text-white p-3 rounded-full hover:bg-primary/90 transition-colors disabled:opacity-50 disabled:cursor-not-allowed"
            aria-label="Send message"
            disabled={isLoading}
          >
            <FontAwesomeIcon
              icon={faPaperPlane}
              className={`text-base ${isLoading ? 'opacity-50' : ''}`}
            />
          </button>
        </div>
        <p className="text-xs text-gray-500 mt-2 text-center">
          K-AI is still in development and might make mistakes. We're continuously improving it.
        </p>
      </form>
    </div>
  )
}

export default AIChat
