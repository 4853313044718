import {
  faArrowLeft,
  faEye,
  faEyeSlash,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import 'animate.css'
import {
  GoogleAuthProvider,
  sendSignInLinkToEmail,
  signInWithEmailAndPassword,
  //signInWithPhoneNumber,
  signInWithPopup,
} from 'firebase/auth'
import React, { useEffect, useState } from 'react'
import { Link, useNavigate } from 'react-router-dom'
import { toast } from 'react-toastify'
import Illustration from '../../../assets/images/illustrations/Certification.png'
import gooleLogo from '../../../assets/images/web/google-logo.png'
import mailLogo from '../../../assets/images/web/mail.png'
import oneTimePassword from '../../../assets/images/web/one-time-password.png'
import { Navbar } from '../../../components'
import { auth, firestore } from '../../../utils/firebase'
import { useAuth } from '../../../utils/firebase/auth'
import './style.scss'
import { setNavigationFunction } from '../../../utils/firebase/auth'
import { collection, query, where, getDocs, addDoc } from 'firebase/firestore'
import { POST_LOGIN_REDIRECT } from '../../../utils/constants'

const LoginPage = () => {
  const { authUser, isLoading } = useAuth()

  const [loginType, setLoginType] = useState(null)
  const navigate = useNavigate()

  // const loginWithEmailOrPhone = (event) => {
  //   event.preventDefault()
  //   event.stopPropagation()
  //   switch (loginType) {
  //     case 'EMAIL_ONLY':
  //       break
  //     case 'EMAIL_PASSWORD':
  //       break
  //     case 'PHONE':
  //       break
  //     default:
  //       console.log('Application Error. Contact support')
  //   }
  // }

  const signInwithGoogle = async (event) => {
    event.stopPropagation()
    try {
      const { user } = await signInWithPopup(auth, new GoogleAuthProvider())

      // Check if user profile exists
      const userProfileRef = collection(firestore, 'userProfiles')
      const q = query(userProfileRef, where('uid', '==', user.uid))
      const querySnapshot = await getDocs(q)

      if (querySnapshot.empty) {
        // Create initial profile
        const userProfileData = {
          uid: user.uid,
          fullName: user.displayName || '',
          email: user.email,
          bio: '',
          region: '',
          city: '',
          edSystem: '',
          level: '',
          school: '',
          subjects: [],
          profileCompleted: false,
          createdAt: new Date(),
          lastUpdated: new Date(),
        }
        await addDoc(collection(firestore, 'userProfiles'), userProfileData)
        toast.success(
          `Welcome to KET Academy, ${
            user.displayName || 'new user'
          }! Let's set up your profile.`
        )
      } else {
        toast.success(`Welcome back, ${user.displayName || 'user'}!`)
      }

      // Always navigate to profile
      navigate('/dashboard/profile', { replace: true })
    } catch (error) {
      toast.error(error.message)
    }
  }

  const handleLoginSuccess = () => {
    navigate(POST_LOGIN_REDIRECT, { replace: true })
  }

  useEffect(() => {
    if (isLoading || !authUser) return

    let mounted = true

    const checkProfileStatus = async () => {
      try {
        const userProfileRef = collection(firestore, 'userProfiles')
        const q = query(userProfileRef, where('uid', '==', authUser.uid))
        const querySnapshot = await getDocs(q)

        if (!mounted) return

        // Only navigate if we're still mounted
        if (mounted) {
          if (
            querySnapshot.empty ||
            !querySnapshot.docs[0].data().profileCompleted
          ) {
            navigate('/dashboard/profile?newUser=true', { replace: true })
          } else {
            navigate('/dashboard/home', { replace: true })
          }
        }
      } catch (error) {
        console.error('Error checking profile status:', error)
      }
    }

    checkProfileStatus()

    return () => {
      mounted = false
    }
  }, [authUser, isLoading, navigate])

  useEffect(() => {
    // Set the navigation function for passwordless signin
    setNavigationFunction(navigate)
  }, [navigate])

  return (
    <div className="login__home">
      <Navbar />
      <div className="left">
        {/* <Link to="/">
          <div className="logo">
            <img src={logo} alt="KET Academy Logo" />
          </div>
        </Link> */}
        {loginType && (
          <LoginContainer
            loginType={loginType}
            setLoginType={setLoginType}
            handleLoginSuccess={handleLoginSuccess}
          />
        )}

        {!loginType && (
          <div className="login__options">
            <h2 className="text-3xl font-bold text-primary my-4 mb-8 text-center">
              Login <span className="text-sm font-semibold">using</span>
            </h2>
            <button onClick={() => setLoginType('EMAIL_ONLY')}>
              <img src={mailLogo} alt="mail"></img>
              Email Only
            </button>
            <button onClick={() => setLoginType('EMAIL_PASSWORD')}>
              <img src={oneTimePassword} alt="pwd"></img>
              Email and Password
            </button>
            <button onClick={signInwithGoogle}>
              <img src={gooleLogo} alt="Google"></img>
              Google
            </button>
            {/* <button onClick={signInwithApple}>
              <img src={appleLogo} alt="Apple"></img>
              Apple
            </button> */}

            {/* <button onClick={() => setLoginType('PHONE')}>
              <img src={phoneLogo} alt="Phone"></img>
              Phone Number
            </button> */}
          </div>
        )}

        <div className="to_login mt-5">
          <span>
            Don't yet have an account?{' '}
            <Link to={'/account/signup'}>Create One.</Link>
          </span>
          <span className="mt-4">
            Forgot Password?{' '}
            <Link to={'/account/recover'}>Recover your account.</Link>
          </span>
        </div>
      </div>

      <div className="right">
        <h1 className="text-3xl font-bold text-center">
          Welcome to KET Academy
        </h1>
        <div className="illustration">
          <img src={Illustration} alt="Illustration" />
        </div>
        <h3 className="text-3xl text-center font-semibold">
          Evolving Education with <br />
          <span className="text-accent"> DIGITAL LEVERAGE</span>
        </h3>
      </div>
    </div>
  )
}

const LoginContainer = ({ loginType, setLoginType, handleLoginSuccess }) => {
  const [email, setEmail] = useState('')
  const [password, setPassword] = useState('')
  const [showPassword, setShowPassword] = useState(false)
  const [isSubmitting, setIsSubmitting] = useState(false)

  const signInWithEmailOrPhone = async (event) => {
    event.preventDefault()
    event.stopPropagation()
    setIsSubmitting(true)

    switch (loginType) {
      case 'EMAIL_ONLY':
        {
          const toastId = toast.loading('Sending login link', {
            position: 'top-left',
            autoClose: false,
          })
          try {
            await sendSignInLinkToEmail(auth, email, {
              url: `${window.location.origin}/dashboard`,
              handleCodeInApp: true,
            })
            window.localStorage.setItem('KET-email-for-sign-in', email)
            toast.update(toastId, {
              render: `A sign in link has been sent to ${email}. Check your email to complete login`,
              type: 'success',
              isLoading: false,
              autoClose: 2000,
            })
          } catch (error) {
            setIsSubmitting(false)
            toast.update(toastId, {
              render: error.message || 'Error signing in',
              type: 'error',
              isLoading: false,
              autoClose: 1000,
            })
          }
        }
        break

      case 'EMAIL_PASSWORD':
        const toastId = toast.loading('Logging you in', {
          position: 'top-left',
          autoClose: false,
        })

        try {
          await signInWithEmailAndPassword(auth, email, password)
          toast.dismiss(toastId)
          handleLoginSuccess()
        } catch (error) {
          toast.update(toastId, {
            render: error.message || 'Error logging in',
            type: 'error',
            isLoading: false,
            autoClose: 1000,
          })
        }
        break

      default:
        alert('Application Error')
    }
    setIsSubmitting(false)
  }

  return (
    <div className="form__container" style={{ display: 'block' }}>
      <span className="back__button" onClick={() => setLoginType(null)}>
        <FontAwesomeIcon icon={faArrowLeft}></FontAwesomeIcon>
      </span>
      <div className="title__text">
        <h2>Login</h2>
      </div>
      <form method="POST" onSubmit={signInWithEmailOrPhone}>
        <div className="field">
          <label htmlFor="">Your Email</label>
          <input
            type="email"
            value={email}
            onChange={(e) => setEmail(e.target.value)}
            placeholder="Enter your email"
            required
          />
        </div>

        {loginType === 'EMAIL_PASSWORD' && (
          <div className="field">
            <label htmlFor="">Your Password</label>
            <input
              className="login-password"
              type={showPassword ? 'text' : 'password'}
              value={password}
              onChange={(e) => setPassword(e.target.value)}
              placeholder="Enter your password"
              required
            />
            <FontAwesomeIcon
              className="show-pass"
              onClick={() => setShowPassword(!showPassword)}
              icon={showPassword ? faEyeSlash : faEye}
            />
          </div>
        )}

        <div className="field">
          <button
            type="submit"
            className="relative mt-4"
            disabled={isSubmitting}
          >
            {isSubmitting ? 'Please wait...' : 'Login'}
          </button>
        </div>
      </form>
    </div>
  )
}

export default LoginPage
