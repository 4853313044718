import { collection, getDocs, query, where } from 'firebase/firestore'
import { capitalize } from 'lodash'
import React, { useCallback, useEffect, useState, useMemo } from 'react'
import ReactPlayer from 'react-player'
import Alert from '../../Alert'
import { categories, levels, subjects } from '../../../utils/constants'
import { firestore } from '../../../utils/firebase'
//import BreadCrumb from '../../BreadCrumb'
import {
  faBook,
  //faChevronDown,
  faFileAlt,
  faSpinner,
  faTimes,
  faUser,
} from '@fortawesome/free-solid-svg-icons'
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome'
import { GlobalWorkerOptions } from 'pdfjs-dist'
import pdfjsWorker from 'pdfjs-dist/build/pdf.worker.entry'

GlobalWorkerOptions.workerSrc = pdfjsWorker

function DashboardLibrary() {
  const [selectedLevels, setSelectedLevels] = useState([])
  const [selectedTypes, setSelectedTypes] = useState([])
  const [selectedSubject, setSelectedSubject] = useState('')
  const [resources, setResources] = useState([])
  const [filteredResources, setFilteredResources] = useState([])
  const [selectedResource, setSelectedResource] = useState(null)
  const [loading, setLoading] = useState(true)
  const [keywords, setKeywords] = useState('')
  const [currentPage, setCurrentPage] = useState(1)
  const resourcesPerPage = 10

  const fetchAllResources = useCallback(async () => {
    const resourcesRef = collection(firestore, 'resources')
    const q = query(resourcesRef)
    const querySnapshot = await getDocs(q)
    const fetchedResources = querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
    setResources(fetchedResources)
    setFilteredResources(fetchedResources)
    setLoading(false)
  }, [])

  useEffect(() => {
    fetchAllResources()
  }, [fetchAllResources])

  const handleSearch = async () => {
    if (!keywords.trim()) {
      Alert.fire({
        icon: 'warning',
        title: 'Empty Search Query',
        text: 'Please enter keywords to search for resources.',
      })
      return
    }

    const resourcesRef = collection(firestore, 'resources')
    const searchKeywords = keywords
      .toLowerCase()
      .trim()
      .split(/\s+/)
      .filter((keyword) => keyword.length >= 2)

    if (searchKeywords.length === 0) {
      Alert.fire({
        icon: 'warning',
        title: 'Invalid Keywords',
        text: 'Please enter valid keywords for search.',
      })
      return
    }

    try {
      const q = query(
        resourcesRef,
        where('keywords', 'array-contains-any', searchKeywords)
      )
      const querySnapshot = await getDocs(q)
      const searchResults = querySnapshot.docs.map((doc) => ({
        id: doc.id,
        ...doc.data(),
      }))

      setResources(searchResults)
      setFilteredResources(searchResults)

      if (searchResults.length === 0) {
        Alert.fire({
          icon: 'info',
          title: 'No Results',
          text: 'No resources were found matching your search keywords.',
        })
      }

      setSelectedLevels([])
      setSelectedTypes([])
      setSelectedSubject('')
    } catch (error) {
      console.error('Search error:', error)
      Alert.fire({
        icon: 'error',
        title: 'Search Error',
        text: 'An error occurred while searching. Please try again.',
      })
    }
  }

  const applyFilters = useCallback(() => {
    let filtered = resources
    if (selectedLevels.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedLevels.includes(resource.level)
      )
    }
    if (selectedTypes.length > 0) {
      filtered = filtered.filter((resource) =>
        selectedTypes.includes(resource.resourceType)
      )
    }
    if (selectedSubject) {
      filtered = filtered.filter(
        (resource) => resource.subject === selectedSubject
      )
    }
    setFilteredResources(filtered)
  }, [resources, selectedLevels, selectedTypes, selectedSubject])

  useEffect(() => {
    applyFilters()
  }, [applyFilters])

  const handleFilterChange = (filterType, value) => {
    switch (filterType) {
      case 'level':
        setSelectedLevels(value ? [value] : [])
        break
      case 'type':
        setSelectedTypes(value ? [value] : [])
        break
      case 'subject':
        setSelectedSubject(value)
        break
      default:
        break
    }
  }

  const paginatedResources = useMemo(() => {
    const startIndex = (currentPage - 1) * resourcesPerPage
    const endIndex = startIndex + resourcesPerPage
    return filteredResources.slice(startIndex, endIndex)
  }, [filteredResources, currentPage])

  const totalPages = Math.ceil(filteredResources.length / resourcesPerPage)

  const handlePageChange = (pageNumber) => {
    setCurrentPage(pageNumber)
    window.scrollTo({ top: 0, behavior: 'smooth' })
  }

  // Reusing the ResourceCard and ResourceModal components from the reference
  const ResourceCard = ({ resource, onClick }) => (
    <div
      className="bg-white rounded-lg shadow-md overflow-hidden cursor-pointer transition-transform duration-300 hover:shadow-xl hover:scale-105 flex flex-col h-full w-full"
      onClick={() => onClick(resource)}
    >
      <div className="h-48 overflow-hidden relative">
        {resource.resourceType === categories.YOUTUBE ? (
          <img
            src={getYouTubeThumbnail(resource.resourceUrl)}
            alt={resource.title}
            className="w-full h-full object-cover"
          />
        ) : (
          <embed
            src={`${resource.resourceUrl}#toolbar=0&navpanes=0&scrollbar=0`}
            type="application/pdf"
            width="100%"
            height="100%"
            className="w-full h-full object-cover"
          />
        )}
        <div className="absolute top-0 right-0 bg-blue-600 text-white px-2 py-1 text-xs font-semibold rounded-bl-lg">
          {resource.resourceType}
        </div>
      </div>
      <div className="p-4 flex-grow flex flex-col">
        <h3 className="text-md font-semibold mb-2 line-clamp-2 flex-grow">
          {capitalize(resource.title)}
        </h3>
        <p className="text-sm text-gray-600 mb-1">
          <span className="font-medium">Subject:</span> {resource.subject}
        </p>
        <p className="text-sm text-gray-600">
          <span className="font-medium">Level:</span> {resource.level}
        </p>
      </div>
      <div className="bg-gray-100 px-4 py-2">
        <button
          className="w-full bg-blue-600 text-white px-4 py-2 rounded-lg hover:bg-blue-700 transition-colors"
          onClick={(e) => {
            e.stopPropagation()
            onClick(resource)
          }}
        >
          Open Resource
        </button>
      </div>
    </div>
  )

  return (
    <div className="p-8">
      <div className="flex items-center gap-2 mb-8 text-sm">
        <span className="text-gray-600">Dashboard</span>
        <span className="text-gray-400">/</span>
        <span className="text-blue-600">Library</span>
      </div>
      <div className="mb-8">
        <h1 className="text-3xl font-bold text-gray-800 mb-4">
          Library Resources
        </h1>
        <p className="text-gray-600">
          Access all educational materials and resources
        </p>
      </div>

      <div className="mb-8">
        <div className="flex flex-col sm:flex-row gap-4 mb-6">
          <input
            type="text"
            placeholder="Search resources..."
            className="flex-grow border-2 border-gray-300 rounded-lg px-4 py-2"
            value={keywords}
            onChange={(e) => setKeywords(e.target.value)}
          />
          <button
            className="bg-blue-600 text-white px-6 py-2 rounded-lg hover:bg-blue-700 transition-colors"
            onClick={handleSearch}
          >
            Search
          </button>
        </div>

        <div className="flex flex-wrap gap-4 mb-6">
          {/* Filter components */}
          {/* Subject Filter */}
          <div className="w-full sm:w-auto">
            <select
              value={selectedSubject}
              onChange={(e) => handleFilterChange('subject', e.target.value)}
              className="w-full border-2 border-gray-300 rounded-lg px-4 py-2"
            >
              <option value="">All Subjects</option>
              {Object.values(subjects).map((subject) => (
                <option key={subject} value={subject}>
                  {subject}
                </option>
              ))}
            </select>
          </div>

          {/* Level Filter */}
          <div className="w-full sm:w-auto">
            <select
              value={selectedLevels[0] || ''}
              onChange={(e) => handleFilterChange('level', e.target.value)}
              className="w-full border-2 border-gray-300 rounded-lg px-4 py-2"
            >
              <option value="">All Levels</option>
              {Object.values(levels)
                .flat()
                .map((level) => (
                  <option key={level} value={level}>
                    {level}
                  </option>
                ))}
            </select>
          </div>

          {/* Type Filter */}
          <div className="w-full sm:w-auto">
            <select
              value={selectedTypes[0] || ''}
              onChange={(e) => handleFilterChange('type', e.target.value)}
              className="w-full border-2 border-gray-300 rounded-lg px-4 py-2"
            >
              <option value="">All Types</option>
              {Object.values(categories).map((type) => (
                <option key={type} value={type}>
                  {type}
                </option>
              ))}
            </select>
          </div>
        </div>
      </div>

      {loading ? (
        <div className="text-center py-8">
          <FontAwesomeIcon
            icon={faSpinner}
            spin
            size="3x"
            className="text-blue-600 mb-4"
          />
          <p className="text-gray-600">Loading resources...</p>
        </div>
      ) : filteredResources.length === 0 ? (
        <div className="text-center py-8">
          <p className="text-gray-600">
            No resources found. Try adjusting your filters.
          </p>
        </div>
      ) : (
        <>
          <div className="grid grid-cols-1 sm:grid-cols-2 lg:grid-cols-3 xl:grid-cols-4 gap-6">
            {paginatedResources.map((resource) => (
              <ResourceCard
                key={resource.id}
                resource={resource}
                onClick={() => setSelectedResource(resource)}
              />
            ))}
          </div>

          {/* Pagination */}
          <div className="mt-8 flex justify-between items-center">
            <div className="text-sm text-gray-700">
              Showing {(currentPage - 1) * resourcesPerPage + 1} to{' '}
              {Math.min(
                currentPage * resourcesPerPage,
                filteredResources.length
              )}{' '}
              of {filteredResources.length} resources
            </div>
            <div className="flex gap-2">
              <button
                onClick={() => handlePageChange(currentPage - 1)}
                disabled={currentPage === 1}
                className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                Previous
              </button>
              <div className="flex gap-1">
                {[...Array(totalPages)].map((_, index) => {
                  const pageNumber = index + 1
                  // Show first page, last page, current page, and pages around current page
                  if (
                    pageNumber === 1 ||
                    pageNumber === totalPages ||
                    (pageNumber >= currentPage - 2 &&
                      pageNumber <= currentPage + 2)
                  ) {
                    return (
                      <button
                        key={pageNumber}
                        onClick={() => handlePageChange(pageNumber)}
                        className={`px-4 py-2 rounded-lg transition-colors ${
                          currentPage === pageNumber
                            ? 'bg-blue-600 text-white'
                            : 'border border-gray-300 hover:bg-gray-50'
                        }`}
                      >
                        {pageNumber}
                      </button>
                    )
                  } else if (
                    pageNumber === currentPage - 3 ||
                    pageNumber === currentPage + 3
                  ) {
                    return (
                      <span
                        key={pageNumber}
                        className="px-4 py-2 text-gray-500 select-none"
                      >
                        ...
                      </span>
                    )
                  }
                  return null
                })}
              </div>
              <button
                onClick={() => handlePageChange(currentPage + 1)}
                disabled={currentPage >= totalPages}
                className="px-4 py-2 border border-gray-300 rounded-lg hover:bg-gray-50 disabled:opacity-50 disabled:cursor-not-allowed transition-colors"
              >
                Next
              </button>
            </div>
          </div>
        </>
      )}

      {selectedResource && (
        <ResourceModal
          resource={selectedResource}
          onClose={() => setSelectedResource(null)}
        />
      )}
    </div>
  )
}

// Helper function for YouTube thumbnails
function getYouTubeThumbnail(url) {
  let videoId
  if (url.includes('youtu.be')) {
    videoId = url.split('youtu.be/')[1]
  } else if (url.includes('youtube.com')) {
    videoId = url.split('v=')[1]
  }
  if (videoId) {
    const ampersandPosition = videoId.indexOf('&')
    if (ampersandPosition !== -1) {
      videoId = videoId.substring(0, ampersandPosition)
    }
  }
  return videoId
    ? `https://img.youtube.com/vi/${videoId}/hqdefault.jpg`
    : `https://www.youtube.com/yts/img/yt_1200-vfl4C3T0K.png`
}

const ResourceModal = ({ resource, onClose }) => (
  <div className="fixed inset-0 bg-black bg-opacity-50 flex justify-center items-center z-50">
    <div className="bg-white rounded-lg p-6 w-11/12 h-[90vh] flex flex-col shadow-2xl">
      <div className="flex justify-between items-center mb-4">
        <h2 className="text-xl font-bold text-gray-800">
          {capitalize(resource.title)}
        </h2>
        <button onClick={onClose} className="text-gray-500 hover:text-gray-700">
          <FontAwesomeIcon icon={faTimes} className="w-6 h-6" />
        </button>
      </div>
      <div className="bg-gray-100 rounded-lg p-4 mb-4 grid grid-cols-1 md:grid-cols-3 gap-4">
        <div className="flex items-center">
          <FontAwesomeIcon icon={faUser} className="text-blue-600 mr-2" />
          <p className="text-sm text-gray-700">
            Subject: <span className="font-semibold">{resource.subject}</span>
          </p>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faFileAlt} className="text-blue-600 mr-2" />
          <p className="text-sm text-gray-700">
            Type: <span className="font-semibold">{resource.resourceType}</span>
          </p>
        </div>
        <div className="flex items-center">
          <FontAwesomeIcon icon={faBook} className="text-blue-600 mr-2" />
          <p className="text-sm text-gray-700">
            Level: <span className="font-semibold">{resource.level}</span>
          </p>
        </div>
      </div>
      <div className="flex-grow overflow-hidden rounded-lg">
        {resource.resourceType === categories.YOUTUBE ? (
          <ReactPlayer
            url={resource.resourceUrl}
            width="100%"
            height="100%"
            controls={true}
            config={{
              youtube: {
                playerVars: { modestbranding: 1 },
              },
            }}
          />
        ) : (
          <embed
            src={`${resource.resourceUrl}#toolbar=1&navpanes=1&scrollbar=1`}
            type="application/pdf"
            width="100%"
            height="100%"
            className="rounded-lg"
          />
        )}
      </div>
    </div>
  </div>
)

export default DashboardLibrary
