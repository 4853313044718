import { db } from './config'
import {
  collection,
  addDoc,
  updateDoc,
  doc,
  query,
  where,
  orderBy,
  getDocs,
  serverTimestamp,
} from 'firebase/firestore'

export const createNewChat = async (userId, agentType, initialMessage) => {
  try {
    const chatRef = await addDoc(collection(db, 'chats'), {
      userId,
      agentType,
      createdAt: serverTimestamp(),
      lastUpdated: serverTimestamp(),
      messages: [
        {
          type: 'bot',
          content: initialMessage,
          timestamp: Date.now(),
        },
      ],
    })
    return chatRef.id
  } catch (error) {
    console.error('Error creating chat:', error)
    throw error
  }
}

export const updateChatMessages = async (chatId, messages) => {
  try {
    const chatRef = doc(db, 'chats', chatId)
    await updateDoc(chatRef, {
      messages,
      lastUpdated: serverTimestamp(),
    })
  } catch (error) {
    console.error('Error updating chat:', error)
    throw error
  }
}

export const getUserChats = async (userId) => {
  try {
    const chatsQuery = query(
      collection(db, 'chats'),
      where('userId', '==', userId),
      orderBy('lastUpdated', 'desc')
    )
    const querySnapshot = await getDocs(chatsQuery)
    return querySnapshot.docs.map((doc) => ({
      id: doc.id,
      ...doc.data(),
    }))
  } catch (error) {
    console.error('Error fetching chats:', error)
    if (error.code === 'failed-precondition') {
      // This means we need to create an index
      console.log('Creating index...')
      // You'll need to click the link in the error message to create the index
    }
    return [] // Return empty array on error
  }
}
